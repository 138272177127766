import React from 'react';
import {TextInput} from 'carbon-components-react';
import Label from './Label';
import InputMask from 'react-input-mask';

export default props => {
	const {field,label,onChange, tooltip} = props;

	const value = ( null === field.value ) ? field.default_value : field.value;
	return <React.Fragment>
		{field.input_mask ?
			<React.Fragment>
			<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
				<div className="bx--form-item">
					<InputMask type={field.type}
					           id={field.key}
					           name={field.name}
					           className="bx--text-input bx--text__input"
					           labeltext=''
					           value={value || ''}
					           required={!!field.required}
					           disabled={!!field.disabled}
					           placeholder={field.placeholder}
					           autoComplete="on"
					           helpertext={field.helperText}
					           onChange={onChange}
					           mask={field.input_mask}
					           maskChar="_"/>
				</div>
			</React.Fragment>
			: <React.Fragment>
			<div className="label-wrapper"><Label htmlFor={field.key}>{label} <span>{field.extra_instructions}</span></Label> {tooltip}</div>
			<TextInput type={field.type}
			             id={field.key}
			             name={field.name}
			             labelText=''
			             value={value || ''}
			             required={!!field.required}
			             disabled={!!field.disabled}
			             placeholder={field.placeholder}
			             autoComplete="on"
			             helperText={field.helperText}
			             onChange={onChange}/>
	 </React.Fragment>
		}
	</React.Fragment>;
};
