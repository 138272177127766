import React from 'react';
import { Select, SelectItem } from 'carbon-components-react';
import MultiSelect from './MultiSelect';
import { map, isArray } from 'lodash';
import { t } from 'i18next';
import Label from './Label';

export default props => {

	const {field,label,onChange,tooltip} = props;

	if ( field.multiple ) {
		return <MultiSelect {...props} />
	}

	const choices = ( typeof field.choices === 'function' ) ? field.choices( props ) : field.choices;
	const selectChoices = choices ? map(choices, (choiceName, value) => (
		<SelectItem
			key={field.name + '-' + value}
			value={value}
			text={choiceName}
		/>
	)) : '';

	let value = field.value || field.default_value;

	if ( isArray( value ) ) {
		value = value[0];
	}
	const initialChoice = ( typeof value === 'undefined' )
		? <SelectItem
			key={field.name + '-' + value}
			value=""
			text={t('-- Select --')}
		/>
		: null;

	return <React.Fragment>
		<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
		<Select
			className=""
			id={field.key}
			name={field.name}
			hideLabel
			value={value}
			required={!!field.required}
			multiple={!!field.multiple}
			onChange={onChange}
			placeholder={t('Select...')}
		>
			{initialChoice}
			{selectChoices}
		</Select>
	</React.Fragment>;

};
