import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { each, keys } from 'lodash';
import { injectReducer } from './ReduxStore';

/**
 * The purpose of this service is to abstract out the pieces that register a controller with Redux so the controller can
 * just take care of defining what it needs to define.
 *
 * The Controller that you pass to this can be a React component, or a plain object.  This function looks for a few
 * properties within the Controller object.  Namely:
 *
 *      defaultProps       - an object listing default props to pass to the controller.  This will define what properties get
 *                           saved to the Redux store for this Controller.
 *      mapStateToProps    - an object that has one ( and only one ) property, the key for which will be the key within
 *                           the redux store where this data is store, and the value for which is a function that accepts
 *                           ( state, action ) as arguments and returns a new state object based on the action ( in
 *                           other words, the reducer ).
 *      mapDispatchToProps - an object containing functions that you would like to expose to the dispatch service.  Like
 *                           the properties defined in defaultProps, these functions will be exposed in the controller
 *                           under this.props.  Each function should return a function that accepts ( dispatch )
 *                           as an argument and should eventually call dispatch( {type: SOME_ACTION, foo: 'bar'} );

 * @param Controller
 * @returns {{}}
 */
export default (Controller) => {

    let mapStateToProps;
    let mapDispatchToProps;

    if ( Controller.mapStateToProps ) {
        const stateName = Object.keys( Controller.mapStateToProps )[0];
        const reducer = ( state = Controller.defaultProps || {}, action ) => {
            return Controller.mapStateToProps[stateName](state,action);
        };
        injectReducer( stateName, reducer );

        mapStateToProps = (state,ownProps) => {
            let map = {};
            each( keys( Controller.defaultProps || {} ), function( key ) {
                map[ key ] = state[ stateName ][ key ];
            });
            each( keys( ownProps ), (key) => {
                map[ key ] = ownProps[ key ];
            });
            return map;
        }
    } else {
        mapStateToProps = null;
    }

    if ( Controller.mapDispatchToProps ) {
        if ( ! Controller.mapDispatchToProps.dispatch ) {
            // This restores the default .dispatch() prop that we want to be present for all reduxified controllers.
            // Allows us to dispatch an action by calling this.props.dispatch({type: FOO});
            Controller.mapDispatchToProps.dispatch = (action) => {
                return (dispatch) => {
                    dispatch(action);
                }
            }
        }
        mapDispatchToProps = dispatch => bindActionCreators(Controller.mapDispatchToProps, dispatch);
    } else {
        mapDispatchToProps = null;
    }

    return withRouter(connect(
        mapStateToProps,
        mapDispatchToProps
    )(Controller));

};
