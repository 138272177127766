import PropTypes from 'prop-types';
import {RESET_PAGE, SETUP_PAGE} from './Page.actions';

export const propTypes = {
	ID: PropTypes.number.isRequired,
	page: PropTypes.object,
};

export const defaultProps = {
	ID: undefined,
	page: null,
};

export const mapStateToProps = {
	page: (state, action) => {
		switch (action.type) {
			case SETUP_PAGE:
				return {
					...state,
					page: action.page
				};
			case RESET_PAGE:
				return {
					...state,
					page: null
				};
			default:
				return state;
		}
	}
};

export const mapDispatchToProps = {};