import React from 'react';
import classNames from 'classnames';
import {Button, Modal, OverflowMenu} from 'carbon-components-react';
import {Link} from 'react-router-dom';
import {history} from '../../services/ReduxStore';
import Headroom from 'react-headroom';
import {map} from 'lodash';
import {t} from "i18next";
import {changeLang, currentLanguage} from "../../services/i18n";

import {BrandLogo} from '../BrandLogo';
import {UserMenu} from '../UserMenu';
import {Icomoon} from '../Icomoon';
import {MainMenu, MainMenuItem} from '../MainMenu';
import {Notifications} from '../Notifications';
import tbkApi from "../../services/tbkApi";

class TopNav extends React.Component {
	static defaultProps = {
		user: null,
		assets: null,
		doLogout: () => void 0,
		doSearch: () => void 0,
		onReadNotification: () => void 0,
		searchTerm: '',
	};

	constructor(props) {
		super(props);

		this.headroom = React.createRef();
		this.tolerance = 0;
		this.pinStart = 0;

		this.state = {
			content: 'menu',
			isOpen: false,
		};
	}

	componentWillMount() {
		this.unlisten = history.listen(() => {
			this.toggleMenu()
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	toggleMenu = (e) => {
		if (!this.state.isOpen && e) {

			// If its not open, open it with the correct content
			this.setState({
				content: e,
				isOpen: true,
			});
			//Log scroll position and set a data attr to hold onto it.
			document.body.dataset.dataPos = window.pageYOffset;
			setTimeout(function () {
				document.body.style.overflow = 'hidden';
				document.body.style.position = 'fixed';
			}, 400);

			// If the top bar isn't shown, pin it and turn off the ability to lose it.
			this.headroom.current.pin();
			this.pinStart = -10000;
			this.tolerance = 10000;

		} else if (this.state.isOpen && e && (e !== this.state.content)) {
			// if its open but different content is chosen
			this.setState({
				content: e,
			});
		} else {
			//Go back to previous scroll position
			document.body.style.overflow = 'auto';
			document.body.style.position = 'relative';
			window.scrollTo(0, document.body.dataset.dataPos);

			// Close Menu
			this.setState({
				isOpen: false,
			});

			this.headroom.current.unfix();
			this.pinStart = 0;
			this.tolerance = 0;
		}
	};

	getNotificationModal = () => {
		if (this.state && Number.isInteger(this.state.openNotificationID)) {
			return <Modal open
			              modalHeading={this.props.notifications.notifications[this.state.openNotificationID].post_title}
			              passiveModal={true}
			              onBlur={() => this.setState({openNotificationID: null})}
			              onRequestClose={() => this.setState({openNotificationID: null})}
			              onRequestSubmit={() => this.setState({openNotificationID: null})}
			>
				<p className="bx--modal-content__text">
					{this.props.notifications.notifications[this.state.openNotificationID].post_content}
				</p>
			</Modal>;
		}
		return null;
	};

	render() {
		const mobileToggleMainClass = classNames({
			'tbk--nav--mobile__button--active': (this.state.isOpen && this.state.content === 'main'),
		});

		const mobileToggleNotificationsClass = classNames({
			'tbk--nav--mobile__button--active': (this.state.isOpen && this.state.content === 'notifications'),
		});

		const mobileMenuClasses = classNames({
			'tbk--nav--mobile__menu': true,
			'tbk--nav--mobile__menu--open': this.state.isOpen,
			'tbk--nav--mobile__menu--main': this.state.content === 'main',
			'tbk--nav--mobile__menu--notifications': this.state.content === 'notifications',
		});

		const assets = this.props.assets;
		// const footerLinks = [];
		// const supportLinks = [];
		const additionalLinks = {};
		const pages = {};
		const logo_navbar = assets.logo_navbar;
		const logo_mobile = assets.logo_mobile;
		const wpDashboard = (!this.props.user.isGuest && this.props.user.wpDashboard) ?
			<Link icon="" to={this.props.user.wpDashboard}
			      target="_blank"
			      className="tbk--main-menu-item--account">
				{t('WP Dashboard')}
			</Link>
			: null;
		return ([
			<React.Fragment key="top-nav">
			<div className="tbk--nav--top">
				<div className="tbk--nav--top__inner">
					<div className="tbk--nav--top__logo">
						<BrandLogo title="la ribambelle" placement="top-nav" link="/">
							{logo_navbar &&
							<img src={logo_navbar} alt="la ribambelle"/>
							}
						</BrandLogo>
					</div>
					<div className="tbk--page-header">
						<div className="tbk--page-header__inner">
							<h2 className="tbk--page-header__title">
								{t('Parent Portal')}
							</h2>
						</div>
					</div>
					{!this.props.user.isGuest &&
					<div className="tbk--nav--top__notification">
						<OverflowMenu
							className="tbk--nav--top__overflow-menu"
							iconDescription="View your notifications"
							renderIcon={iconProps => <div {...iconProps}>
								<Notifications kind="badge" notifications={this.props.notifications}/>
							</div>}
							ariaLabel={t('Notifications')}
						>
							<Notifications kind="full" notifications={this.props.notifications}
							               onClickItem={index => {
								               this.setState({openNotificationID: index});
								               //call the API with the post's ID, not the array ref
								               const ID = this.props.notifications.notifications[index].ID;
								               tbkApi.readNotification(ID);
								               this.props.onReadNotification(ID)
							               }}/>
						</OverflowMenu>
					</div>
					}
					<div className="tbk--nav--top__language">
						<div>
							<Button onClick={() => changeLang('fr')}
							        className={(currentLanguage() === 'fr') ? 'active' : ''}>FR</Button>
							<Button onClick={() => changeLang('en')}
							        className={(currentLanguage() === 'en') ? 'active' : ''}>EN</Button>
						</div>
					</div>
					{!this.props.user.isGuest &&
					<div className="tbk--nav--top__user">
						<OverflowMenu
							className="tbk--nav--top__overflow-menu"
							iconDescription={t('User Profile Options')}
							renderIcon={iconProps => <div {...iconProps}>
								<UserMenu kind="dropdown" user={this.props.user}/>
							</div>}
							ariaLabel={t('User menu')}
						>
							<UserMenu key="usermenu" kind="navbar" user={this.props.user} assets={this.props.assets}/>
							{map(additionalLinks, pageID =>
								<li key={'additional-link-' + pageID} className="bx--overflow-menu-options__option"
								    role="menuitem">
									<Link className="bx--link bx--overflow-menu-options__btn" to={pages[pageID].path}>
										{pages[pageID].title}
									</Link>
								</li>
							)}
						</OverflowMenu>
					</div>}
					{!this.props.user.isGuest &&
					<Button key="logout" kind="danger--primary" onClick={this.props.doLogout}>{t('Logout')}</Button>
					}
				</div>
			</div>
			{this.getNotificationModal()}
			</React.Fragment>,

			<div key="top-nav-mobile" className="tbk--nav--top__mobile">
				<Headroom className="tbk--nav--top__headroom" ref={this.headroom} pinStart={this.pinStart}
				          downTolerance={this.tolerance}>
					<div className="tbk--nav--top__inner">
						{this.props.searchElement}
					</div>
				</Headroom>
			</div>,

			<div key="mobile-nav" className="tbk--nav--mobile">
				<div className="tbk--nav--mobile__inner">
					<div className="tbk--nav--mobile__logo">
						<BrandLogo title="la ribambelle" placement="mobile-nav" link="/" tabIndex="4">
							{logo_mobile &&
							<img src={logo_mobile} alt="la ribambelle"/>
							}
						</BrandLogo>
					</div>
					<div className="tbk--nav--top__language tbk--nav--mobile__button--no-label">
						<Button onClick={() => changeLang('fr')}
						        className={(currentLanguage() === 'fr') ? 'active' : ''}>FR</Button>
						<Button onClick={() => changeLang('en')}
						        className={(currentLanguage() === 'en') ? 'active' : ''}>EN</Button>
					</div>
					{!this.props.user.isGuest &&
					<div className="tbk--nav--mobile__button tbk--nav--mobile__button--no-label">
						<Button kind="ghost" onClick={() => this.toggleMenu('notifications')}
						        className={mobileToggleNotificationsClass}>
							<span className="tbk--nav--mobile__button-inner">
								<Notifications kind="badge" notifications={this.props.notifications}/>
								<div className="tbk--nav--mobile__button-text">{t('Notifications')}</div>
							</span>
						</Button>
					</div>
					}
					{!this.props.user.isGuest &&
					<div className="tbk--nav--mobile__button tbk--nav--mobile__button--no-label">
						<Button kind="ghost" onClick={() => this.toggleMenu('main')} className={mobileToggleMainClass}>
							<span className="tbk--nav--mobile__button-inner--brand tbk--nav--mobile__button-inner">
								<Icomoon icon="menu"/>
								<div className="tbk--nav--mobile__button-text">{t('Menu')}</div>
							</span>
						</Button>
					</div>
					}
					<div className={mobileMenuClasses}>
						{!this.props.user.isGuest &&
						<ul className="tbk--nav--mobile__canvas tbk--nav--mobile__canvas--notifications">
							<Notifications kind="full" notifications={this.props.notifications}
							               onClickItem={index => {
								               this.setState({openNotificationID: index});
								               //call the API with the post's ID, not the array ref
								               const ID = this.props.notifications.notifications[index].ID;
								               tbkApi.readNotification(ID);
								               this.props.onReadNotification(ID)
							               }}/>
						</ul>
						}
						{!this.props.user.isGuest &&
						<div className="tbk--nav--mobile__canvas tbk--nav--mobile__canvas--main">
							<MainMenu kind="mobile">
								<MainMenuItem className="tbk--nav--mobile--item menu__brand-02" title={t("Dashboard")}
								              icon="family-portal" href="/" exact/>
								<MainMenuItem className="tbk--nav--mobile--item menu__brand-03" title={t("Resources")}
								              icon="resources" href="/resources"/>
								<MainMenuItem className="tbk--nav--mobile--item menu__brand-01" title={t("Key Dates")}
								              icon="calendar" href="/key-dates"/>
								<MainMenuItem className="tbk--nav--mobile--item menu__brand-04" title={t("Contact")}
								              icon="contact" href="/contact"/>

								<MainMenuItem title={t("My Account")} icon="" href="/edit-profile"
								              className="tbk--main-menu-item--account"/>
								{(assets.appPages && assets.appPages.privacy_policy && assets.appPages.privacy_policy.ID) ?
									<MainMenuItem title={assets.appPages.privacy_policy.title} icon=""
									              href={assets.appPages.privacy_policy.path}
									              className="tbk--main-menu-item--account"/> : null}
								{(assets.appPages && assets.appPages.accessibility_policy && assets.appPages.accessibility_policy.ID) ?
									<MainMenuItem title={assets.appPages.accessibility_policy.title} icon=""
									              href={assets.appPages.accessibility_policy.path}
									              className="tbk--main-menu-item--account"/> : null}
								{wpDashboard}
								<MainMenuItem title={t("Log Out")} icon="" href="#" onClick={this.props.doLogout}
								              className="tbk--main-menu-item--logout"/>
							</MainMenu>
						</div>
						}
					</div>
				</div>
			</div>
		])
	}
}

export {TopNav};
