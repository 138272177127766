import React, {Component} from 'react';
import {Loading, Modal} from 'carbon-components-react';
import {Redirect, Route as ReactRoute, Switch} from 'react-router-dom';
import {APP_HEARTBEAT, SET_APP_DATA, SET_APP_NOTIFICATIONS, READ_NOTIFICATION, SET_APP_USER} from './App.actions';
import Reduxify from '../../services/ReduxifyController';
import asyncComponent from '../../components/AsyncComponent';
import tbkApi from '../../services/tbkApi';
import {currentLanguage} from "../../services/i18n";
import GoogleTagManager from '../../components/GoogleTagManager';
import {SW_NEW_CONTENT} from '../../index';
import {defaultProps, mapDispatchToProps, mapStateToProps, propTypes} from './App.props';
import qs from 'qs';
import {t} from "i18next";

import Page from '../Page';
import {MainMenu, MainMenuItem} from '../../components/MainMenu';
import {TopNav} from '../../components/TopNav';
import {loadUsersnap} from "../../components/Usersnap";
import {map} from "lodash";
import NotFound from "../../components/NotFound/NotFound";

const UserSetup = asyncComponent(() => import('../User/UserSetup'));
const Dashboard = asyncComponent(() => import('../Dashboard'));
const Applications = asyncComponent(() => import('../Applications'));
const Children = asyncComponent(() => import('../Children'));
const Guardians = asyncComponent(() => import('../Guardians'));
const Resources = asyncComponent(() => import('../Resources'));
const Contact = asyncComponent(() => import('../Contact'));
const Login = asyncComponent(() => import('../Login'));
const Maintenance = asyncComponent(() => import('../Maintenance'));
const Registration = asyncComponent(() => import('../Registration'));
const ResetPassword = asyncComponent(() => import('../ResetPassword'));
const Events = asyncComponent(() => import('../Events'));

/**
 * This is a wrapper to the regular <Route/> component that allows us to set the document.title ( via the setTitle
 * function that follows ).
 *
 * @param props
 * @returns {XML}
 * @constructor
 */
export const Route = props => {
	const {component,render,title,...other} = props;
	if ( title ) {
		return <ReactRoute {...other} render={routeProps => {
			const Component = component || render; // so we can <JSX/> it.
			setTitle( title );
			return <Component {...routeProps} />;
		}}/>;
	} else {
		setTitle( null );
		return <ReactRoute {...props}/>
	}
};

let originalTitle;
export const setTitle = title => {
	if ( ! originalTitle ) {
		originalTitle = document.title;
	}
	document.title = ( title ? title + ' | ' : '' ) + originalTitle;
};

class App extends Component {

	static propTypes = propTypes;
	static defaultProps = defaultProps;
	static mapStateToProps = mapStateToProps;
	static mapDispatchToProps = mapDispatchToProps;

	constructor(props) {
		super(props);
		tbkApi.prepare().then(() => {
			tbkApi.getAppData().then(data => {
				this.props.dispatch({
					type: SET_APP_DATA,
					assets: data.assets || {},
				});
			});
			tbkApi.getUser().then(user => {
				this.props.dispatch({
					type: SET_APP_USER,
					user: {
						...user,
						// We're letting Administrators view the application summary within the app.  In order to
						// prevent the redirect to dashboard && setup page, we'll set those trigger variables to null
						...( this.isApplicationSummary() ? {
							wpDashboard: null,
							requiresSetup: null,
						} : {})
                    }
				});
				tbkApi.getNotifications().then(notifications => {
					this.props.dispatch({
						type: SET_APP_NOTIFICATIONS,
						notifications: notifications
					});
				}).catch(e => {
					console.log(e);
				});
			}).catch(e => {
				this.props.dispatch({
					type: SET_APP_USER,
					user: {isGuest: true}
				})
			});
		}).catch(e => {
			// Things is bad, we can't call the API, wonder what we should do?
			tbkApi.logger(e);
			this.props.dispatch({
				type: APP_HEARTBEAT,
				ping: false
			});
		});
		this.handleNewContent = this.handleNewContent.bind(this);
	}

	componentDidMount() {
		document.addEventListener(SW_NEW_CONTENT, this.handleNewContent);
	}

	componentWillUnmount() {
		document.removeEventListener(SW_NEW_CONTENT, this.handleNewContent);
	}

	handleNewContent() {
        this.props.dispatch({
            type:SW_NEW_CONTENT
        });
	}

	isApplicationSummary() {
		return this.props.location.pathname.match( /^\/application-summary/ );
	}

	render() {
		const {user, assets, notifications, location, ping} = this.props;

		// Because many of our checks on location.pathname below explicitly do not look for a trailing slash,
		// we're going to strip trailing slash.
		if ( location.pathname.match( /.+\/$/ ) ) {
			const to = location.pathname.replace(/\/$/, '') + location.search + location.hash;
			return <Redirect to={to} replace />
		}

		if (ping === false) {
			/**
			 * The system is down.  Show a Maintenance page
			 */
			if ('/system-status' === location.pathname) {
				return <div className="app">
					<Maintenance/>
				</div>
			} else {
				return <Redirect to="/system-status"/>
			}
		} else if (ping === true && '/system-status' === location.pathname) {
			return <Redirect to="/"/>
		}

		const newContentModal = this.props.newContentAvailable ? <Modal
			open
			primaryButtonText={t('Refresh Now')}
			secondaryButtonText={t('Later')}
			onRequestClose={() => this.props.dispatch({type: SW_NEW_CONTENT, clear: true})}
			onRequestSubmit={() => window.location.reload()}
		>
			<p className="bx--modal-content__text">
				{t('The la ribambelle parent portal has just been updated to the latest release. Please refresh your browser now to ensure you are running the latest version.')}
			</p>
		</Modal> : null;
		if (user && assets) {
			const analytics = ('development' === process.env.NODE_ENV || !assets.gtmCode) ? '' :
					<GoogleTagManager gtmId={assets.gtmCode}/>;

			// Oddly, usersnap causes any console.logs in later code to appear as if they're coming in from a loader.js
			// from UserSnap.  Weird.  So, I'm disabling it for dev environment explicitly
			if ( ( user && user.can_usersnap ) && 'development' !== process.env.NODE_ENV) {
				loadUsersnap("03221ee2-dd0a-4c67-884e-0a726d66004f");
			}

			if (user.isGuest) {
				/**
				 * We don't have a logged-in user.  If we're not on the login page, then redirect there, otherwise,
				 * render the login page
				 */
				if ('/login' === location.pathname) {
					return <div className="app">
					<header className="app__header">
						<TopNav doLogout={this.props.doLogout} user={user} assets={assets}
										currentPath={this.props.location.pathname}/>
					</header>
						{newContentModal}
						<Login assets={assets}/>
					</div>
				} else if ('/reset-password' === location.pathname) {
					const resetPasswordProps = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
					return <div className="app">
					<header className="app__header">
						<TopNav doLogout={this.props.doLogout} user={user} assets={assets}
										currentPath={this.props.location.pathname}/>
					</header>
						{newContentModal}
						<ResetPassword resetPasswordProps={resetPasswordProps}/>
					</div>
				} else if ('/register' === location.pathname) {
					return <div className="app">
					<header className="app__header">
						<TopNav doLogout={this.props.doLogout} user={user} assets={assets}
										currentPath={this.props.location.pathname}/>
					</header>
						<Registration assets={assets}/>
					</div>;
				} else {
					let to = {
						pathname: '/login'
					};
					//this is causing URL weirdness
					if (this.props.location.pathname !== '/') {
						to.search = '?redirect=' + this.props.location.pathname;
					}
					return <Redirect to={to}/>
				}
			} else if (user.requiresSetup) {
				/**
				 * We have a user who is currently using their default password.  We need them to reset that password
				 * before doing anything
				 */
				if ('/setup' === location.pathname) {
					return <div className="app">
						{analytics}
						{newContentModal}
						<header className="app__header">
							<TopNav doLogout={this.props.doLogout} user={user} assets={assets}
							        currentPath={this.props.location.pathname}/>
						</header>
						<div className="app__body">
							<div id="main" className="app__body__main welcome">
								<Route path="/setup" key="setup" component={UserSetup} />
							</div>
						</div>
					</div>
				} else {
					return <Redirect to="/setup"/>
				}
			} else if ('/setup' === location.pathname || '/reset-password' === location.pathname || '/register' === location.pathname || '/login' === location.pathname) {
				//If you're already correctly logged in, redirect these setup routes to the dashboard
				return <Redirect to="/" />
			} else if ( this.isApplicationSummary() ) {
				return <Route exact path="/application-summary/:ID" component={Applications} />
			} else {
				return <div className="app">
					{analytics}
					{newContentModal}
					<header className="app__header">
						<TopNav doLogout={this.props.doLogout} user={user} assets={assets} notifications={notifications}
						        onReadNotification={id => this.props.dispatch({
							        type: READ_NOTIFICATION,
							        id
						        })}
						        currentPath={this.props.location.pathname}/>
					</header>
					<div className="app__body">
						<div id="menu" className="app__body__sidenav">
							<MainMenu kind="side-nav" role="menubar" aria-label={t('Main menu')}>
								<MainMenuItem className="menu__brand-02" title={t('Dashboard')} icon="family-portal"
								              href="/" exact/>
								<MainMenuItem className="menu__brand-03" title={t('Resources')} icon="resources"
								              href="/resources"/>
								<MainMenuItem className="menu__brand-01" title={t('Key Dates')} icon="calendar"
								              href="/key-dates"/>
								<MainMenuItem className="menu__brand-04" title={t('Contact')} icon="mail"
								              href="/contact"/>
								<MainMenuItem className="menu__brand-02" title={t('My Account')} icon="gear"
								              href="/edit-profile"/>
							</MainMenu>
						</div>
						<div id="main" className="app__body__main">
							<Switch>
								<Route exact path="/" component={Dashboard}/>
								<Route exact path="/dashboard" component={Dashboard}/>
								<Route path="/resources" title={t('Resources')} component={Resources}/>
								<Route exact path="/contact" title={t('Contact')} component={Contact}/>
								<Route exact path="/applications/:ID(new)/:childID([0-9]+)?" title={t('Application')} component={Applications}/>
								<Route exact path="/applications/:ID([0-9]+)" title={t('Application')} component={Applications}/>
								<Route exact path="/children/:ID(new|[0-9]+)" title={t('Child')} component={Children}/>
								<Route exact path="/guardians/:ID(new|[0-9]+)" title={t('Parent/Guardian')} component={Guardians}/>
								<Route path="/edit-profile" title={t('My Account')} component={UserSetup}/>
								<Route path="/key-dates/:taxonomy/:slug/:post_name" title={t('Key Dates')} component={Events}/>
								<Route path="/key-dates/:taxonomy/:slug" title={t('Key Dates')} component={Events}/>
								<Route path="/key-dates/:post_name" title={t('Key Dates')} component={Events}/>
								<Route path="/key-dates" title={t('Key Dates')} component={Events}/>
								{map(assets.pages, page => {
									if (page.path[currentLanguage()] === location.pathname) {
										return <Route key={page.ID} path={page.path[currentLanguage()]}
										              render={() => <Page ID={page.ID}/>}/>;
									} else if (Object.values(page.path).includes(location.pathname)) {
										return <Redirect key={page.ID} to={page.path[currentLanguage()]}/>
									}
								})}
								<Route title={t('Page not found.')} component={NotFound}/>
							</Switch>
						</div>
						</div>
				</div>;
			}
		} else {
			/**
			 * We don't yet know if we have an authenticated user.  Show the loading mask
			 */
			return (
				<div className="app">
					<Loading withOverlay={true}/>
				</div>
			)
		}
	}
}

export default Reduxify(App);
