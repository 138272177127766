import { stringify } from 'qs';
import store from './ReduxStore';
import { APP_HEARTBEAT } from '../containers/App';
import {currentLanguage} from './i18n';

const logger = (message) => {
	if ( 'development' === process.env.NODE_ENV ) {
		console.log( message );
	}
};

const tbkApi = {
	map: process.env.REACT_APP_API_MAP || null,
	getApiBase: () => (
		( process.env.REACT_APP_API_BASE || '/api/' ).replace(/\/$/, '' ) // strip trailing slash
	),
	logger: logger,
	prepare: function() {
		const me = this;
		if ( ! me.map ) {
			me.map = new Promise( (resolve, reject) => {
				me.request('/api/map').then( (map) => {

					me.map = {};

					map.forEach( (point) => {
						me.map[ point.function ] = point;
						me[ point.function ] = ( ...data ) => {
							const req = me.determineRequest( point, data );

							if ( req ) {
								return me.request( req.endpoint, req.method, req.parameters );
							}
						};
					});

					resolve( map );
				}).catch( e => {
					reject( 'Unable to obtain API map' );
				})
			});
		}
		return Promise.resolve( me.map );
	},
	getUri: function(func, ...args) {
		if ( this.map[ func ] ) {
			const req = this.determineRequest( this.map[ func ], args );
			return req ? this.getApiBase() + req.endpoint : undefined;
		}
		return undefined;
	},
	determineRequest: (point, parms) => {
		const req = {
			method: point.method.toUpperCase(),
			endpoint: point.route,
			parameters: parms || [],
		};

		// One by one, replace any {parameters} in the endpoint with the next item in parameters
		// Note, this does not yet know how to handle optional parameters parameters.
		// See https://www.slimframework.com/docs/v3/objects/router.html#how-to-create-routes
		try {
			req.endpoint = req.endpoint.replace(/{[^}]+}/g, function() {
				if (req.parameters.length) {
					return req.parameters.shift();
				}
				throw new Error( 'Not enough parameters passed for ' + req.endpoint );
			});
		} catch( e ) {
			logger( e );
			return false;
		}

		// If there are any parameters left, then we un-array-ify $parameters ( just set it to the first member )
		// Note, there is a limitation here that we can never pass more than one argument ( apart from arguments
		// that go into the endpoint ) to an SDK method.
		req.parameters = req.parameters.length ? req.parameters.shift() : null;

		return req;
	},
	activeRequests: {},
	request: function( endpoint, method, data ) {
		if ( ! method ) {
			method = 'GET';
		} else {
			method = method.toUpperCase();
		}
		let url = this.getApiBase() + endpoint;
		const abortKey = method + '/' + url;
		let args = {
			credentials: 'include',
			cache: 'no-cache',
			method: method,
			headers: {
				'Accept': 'application/json',
				'Accept-Language': currentLanguage(),
			}
		};

		const dataStringified = data ? stringify( data ) : null;
		const now = new Date();
		const timestamp = parseFloat( now.getSeconds() + now.getMilliseconds() / 1000 );
		if ( this.activeRequests[ abortKey ] ) {
			if ( this.activeRequests[ abortKey ].data === dataStringified ) {
				// this request is already active with the exact same data.  Don't bother calling the API again.
				return new Promise((resolve,reject)=>{});
			}
			if ( this.activeRequests[ abortKey ].controller ) {
				this.activeRequests[ abortKey ].controller.abort();
			}
		}
		this.activeRequests[ abortKey ] = {
			controller: window.AbortController ? new window.AbortController() : null,
			data: dataStringified,
			timestamp
		};
		if ( this.activeRequests[ abortKey ].controller ) {
			args.signal = this.activeRequests[ abortKey ].controller.signal;
		}

		if ( data ) {
			if ( ['GET','DELETE'].indexOf( method ) !== -1 ) {
				// delete & get parms are passed in via query string
				url += '?' + dataStringified;
			} else {
				args.body = ( data instanceof FormData ) ? data : this.toFormData( data );
			}
		}

		if ( ['GET','PUT','POST','DELETE'].indexOf( method ) === -1 ) {
			args.method = 'POST';
			args.headers['X-HTTP-Method-Override'] = method;
		}

		return new Promise( (resolve, reject ) => {
			fetch( url, args ).then( response => {
				if ( this.activeRequests[ abortKey ].timestamp !== timestamp ) {
					// in case this browser doesn't have AbortController, or if that doesn't work ( looking at you Safari )
					// then we check the timestamp if the response isn't from the current request, then we abort, doing nothing.
					return;
				}

				delete this.activeRequests[ abortKey ]; // successful, delete this so same request can run again at another time
				const type = response.headers.get('content-type') ? response.headers.get('content-type').split(';')[0] : 'text/html';

				if ( 503 === response.status ) {
					store.dispatch({
						type: APP_HEARTBEAT,
						ping: false
					});
					reject();
				}

				if ( 'application/json' === type ) {
					response.json().then( json => {
						if ( json.hasOwnProperty('data') ) {
							if ( json.success ) {
								resolve( json.data );
							} else {
								reject( json.data );
							}
						} else if ( json.message ) {
							reject( json.message );
						}
					}).catch( e => {
						logger( e );
						reject();
					});
				} else {
					reject( response );
				}
			}).catch( (e) => {
				if ( 'The user aborted a request.' !== e.message ) {
					// Above exception is when we've cancelled the request ( because the user is typing and another
					// request has come in before the previous one has finished.  All other exceptions, throw again.
					throw e;
				}
			});
		});
	},
	// takes a {} object and returns a FormData object
	// thanks https://gist.github.com/ghinda/8442a57f22099bdb2e34
	toFormData: object => {
		const toFormData = (obj, form, namespace) => {
			let fd = form || new FormData();
			let formKey;

			for ( let property in obj ) {
				if ( obj.hasOwnProperty( property ) ) {
					if ( namespace ) {
						formKey = namespace + '[' + property + ']';
					} else {
						formKey = property;
					}

					// if the property is an object, but not a File, use recursivity.
					if ( obj[ property ] instanceof Date ) {
						fd.append( formKey, obj[ property ].toISOString() );
					} else if ( typeof obj[ property ] === 'object' && !( obj[ property ] instanceof File ) ) {
						toFormData( obj[ property ], fd, formKey );
					} else { // if it's a string or a File object
						fd.append( formKey, obj[ property ] );
					}
				}
			}
			return fd;
		};

		return object ? toFormData( object ) : null;
	},
};

export default tbkApi;
