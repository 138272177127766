import React, {Component} from 'react';
// import store from '../../services/ReduxStore';
// import {map} from 'lodash';
// import {t} from 'i18next';

class PageFooter extends Component {

	render() {
		// const assets = store.getState().app.assets;
		// const user = store.getState().app.user;
		// const footerCopyright = assets.footerCopyright;
		// const footerLinks = assets.footerLinks;
		// const pages = store.getState().app.assets.pages;
		// const logoFooter = store.getState().app.assets.logo_footer;

		return (
			<div className="tbk--page-footer"></div>
		)
	}
}

export {PageFooter};
