import React from 'react';
import {Button} from 'carbon-components-react';

export default props => {
	const {field} = props;

	//We are treating all buttons as submit buttons unless otherwise specified.
	//GravityForms automatically appends a submit button of type 'button' to the end of the form fields.
	return <Button
		type="submit"
		id={field.key}
		name={field.name}>
		{field.label}
	</Button>;
};

