import React from 'react';
import { NumberInput } from 'carbon-components-react';
import Label from './Label';

export default props => {

	const {field,label,onChange,tooltip} = props;

	const min = parseFloat( field.min );
	const max = parseFloat( field.max );
	const step = parseFloat( field.step );
	const value = parseFloat( field.value );
	return <React.Fragment>
	<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
	<NumberInput
		type={field.type}
		id={field.key}
		label=''
		name={field.name}
		value={isNaN( value ) ? null : value}
		required={!!field.required}
		disabled={!!field.disabled}
		placeholder={field.placeholder}
		autoComplete={field.name}
		helperText={field.helperText}
		min={isNaN( min ) ? null : min}
		max={isNaN( max ) ? null : max}
		step={isNaN( step ) ? null : step}
		onChange={onChange}
	/></React.Fragment>;
};
