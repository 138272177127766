import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Button } from 'carbon-components-react';
import { Icomoon } from '../Icomoon';

const propTypes = {
	children: PropTypes.node,
	tabs: PropTypes.array,
	currentIndex: PropTypes.number,
	onChange: PropTypes.func,
	className: PropTypes.string,
	kind: PropTypes.oneOf(['mobile', 'side-nav']),
	isOpen: PropTypes.bool,
};

const defaultProps = {
	kind: 'mobile',
	tabs: [],
	currentIndex: 0,
	onChange: ()=> {},
};


class MobileProgressNav extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: !!props.isOpen,
		};

		this.toggleCollapse = this.toggleCollapse.bind(this);
	};

	toggleCollapse() {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}));
	}

	closeCollapse() {
		this.setState(prevState => ({
			isOpen: false
		}));
	}


	render() {
		const wrapperClasses = classNames(
			'tbk--mobile-progress-nav',
			{
				'tbk--progress-nav--open' : this.state.isOpen === true,
			},
			this.props.className
		);

		const childClasses = classNames(
			'tbk--progress-nav',
			{
				'tbk--progress-nav--open' : this.state.isOpen === true,
			},
			this.props.className
		);

		const title = ( this.props.tabs && this.props.tabs[ this.props.currentIndex ] ) ? this.props.tabs[ this.props.currentIndex ].label : '';

		return(
			<div key="tbk--mobile-progress-nav" className={wrapperClasses} aria-expanded={this.state.isOpen ? 'true' : 'false'}>
				<div className="tbk--mobile-progress-nav__inner">
					<div className="tbk--mobile-progress-nav__controls">
						<span className="tbk--mobile-progress-nav__title">{title}</span>
						<div className="tbk--mobile-progress-nav__buttons">
							<Button kind="primary" type="button" onClick={() => { this.closeCollapse(); this.props.onChange(this.props.currentIndex - 1)} } disabled={this.props.currentIndex > 0 ? false : true}><span className="sr-only">{t('Previous')}</span><Icomoon icon="prev" /></Button>
							<Button kind="primary" type="button" onClick={() => { this.closeCollapse(); this.props.onChange(this.props.currentIndex + 1)} } disabled={this.props.currentIndex < (this.props.tabs.length - 1) ? false : true}><span className="sr-only">{t('Next')}</span><Icomoon icon="next" /></Button>
						</div>
						<div className="tbk--mobile-progress-nav__menu-button tbk--nav--mobile__button--no-label">
							<Button id="tbk--mobile-progress-nav__submenu-btn" className="tbk--nav--mobile__button" aria-expanded={this.state.isOpen ? 'true' : 'false'} kind="ghost" onClick={this.toggleCollapse}>
								<span className="tbk--mobile-progress-nav__menu-button--inner">
								{this.state.isOpen ? <Icomoon icon="delete"/> : <Icomoon icon="menu"/> }
								<div className="tbk--nav--mobile__button-text">{t('Menu')}</div>
								</span>
							</Button>
						</div>
					</div>
					<ul id="tbk--mobile-progress-nav__submenu" className={childClasses} role="menu" aria-expanded={this.state.isOpen ? 'true' : 'false'}>
						{this.props.tabs.map( ( page, index ) => {
							const label = <Button type="button" onClick={() => {this.closeCollapse(); this.props.onChange(index)} }>{page.label}</Button>;
							return <li key={index} className={page.className}>{label}</li>;
						} )}
					</ul>
				</div>
			</div>
		)
	};
}

MobileProgressNav.propTypes = propTypes;
MobileProgressNav.defaultProps = defaultProps;

export { MobileProgressNav };
