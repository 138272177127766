import React from 'react';
import {DatePicker, DatePickerInput} from 'carbon-components-react';
import {t} from 'i18next';
import {currentLanguage} from '../../../services/i18n';

export default props => {

	const {field,label,onChange} = props;

	return <DatePicker
		locale={currentLanguage()}
		datePickerType="single"
		dateFormat="Y-m-d"
		maxDate={field.max || ''}
		minDate={field.min || ''}
		value={field.value || ''}
		onChange={(date,value,picker) => {
			onChange({
				target:picker.input
			});
		}}
	>
		<DatePickerInput
			labelText={label}
			placeholder={t("YYYY-MM-DD")}
			autoComplete="off"
			pattern="\d{4}-\d{2}-\d{2}"
			id={field.key}
			name={field.name}
			value={field.value || ''}
			required={!!field.required}
		/>
	</DatePicker>
};
