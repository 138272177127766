import React from 'react';
import { Checkbox, FormGroup } from 'carbon-components-react';
import Label from './Label';
import { map, isArray, indexOf } from 'lodash';
import {slugify} from "../../MainMenu/functions";

export default props => {
	const {field,label,onChange,tooltip} = props;

	const value = ( null === field.value ) ? field.default_value : field.value;

	const choices = field.choices ? map(field.choices, (choiceName, choiceValue) => {
		const choiceSlug = slugify(choiceValue);
		return <Checkbox
			key={field.name + '-' + choiceSlug}
			value={choiceValue}
			id={field.name + '-' + choiceSlug}
			labelText={choiceName}
			name={field.name + '[]'}
			checked={isArray(value) ? (indexOf(value, choiceValue) !== -1) : ( value === choiceValue )}
			onChange={(checked,id,e) => onChange(e)}
		/>
	}) : '';

	return <React.Fragment>
		<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
		<FormGroup legendText="">{choices}</FormGroup>
	</React.Fragment>;
};
