import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { SkeletonText } from 'carbon-components-react';
import { Icomoon } from '../Icomoon';

const propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	icon: PropTypes.string,
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	skeleton: PropTypes.bool,
};

const defaultProps = {
	title: '',
	skeleton: false,
};

const PageHeader = ({ children, className, title, icon, skeleton, ...other }) => {
	const wrapperClasses = classNames(
		'tbk--page-header',
		{
			'tbk--page-header--icon': icon,
			'tbk--page-header--skeleton': skeleton,
		},
		className
	);

	if (skeleton) {
		return (
			<div className={wrapperClasses} {...other}>
				<div className="tbk--page-header__inner">
						<h1 className="tbk--page-header__title"><SkeletonText style={{width: "50%"}} heading /></h1>
						<div className="tbk--page-header__description"><SkeletonText style={{width: "75%"}}/></div>
					</div>
			</div>
		);
	} else {
		return (
			<div className={wrapperClasses} {...other}>
				{(title || children) &&
					<div className="tbk--page-header__inner">
						<h1 className="tbk--page-header__title">{title}</h1>
						<div className="tbk--page-header__description">{children}</div>
					</div>
				}
				{icon &&
					<div className="tbk--page-header__icon hide-print">
						<Icomoon icon={icon} size="fit" />
					</div>
				}
			</div>
		);
	}
};


PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export { PageHeader };
