import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Form, InlineNotification, TextInputSkeleton, SkeletonText } from 'carbon-components-react';
import Reduxify from '../../services/ReduxifyController';
import tbkApi from '../../services/tbkApi';
import { propTypes, defaultProps, mapStateToProps, mapDispatchToProps } from './props';
import { POST_CONTENT_GFORM } from '../../components/PostContent';
import { FormFields } from '../../components/FormFields';
import { LOAD_GRAVITY_FIELDS } from './actions';

class GravityForms extends Component {
	static propTypes = propTypes;
	static defaultProps = defaultProps;
	static mapStateToProps = mapStateToProps;
	static mapDispatchToProps = mapDispatchToProps;

	constructor(props) {
		super(props);
		this.handleGravityEvent = this.handleGravityEvent.bind(this);
		this.renderFormToElement = this.renderFormToElement.bind(this);
	}

	componentDidMount() {
		document.addEventListener( POST_CONTENT_GFORM, this.handleGravityEvent, true );
	}

	componentWillUnmount() {
		document.removeEventListener( POST_CONTENT_GFORM, this.handleGravityEvent, true );
	}

	handleGravityEvent( e ) {
		const data = e.target.dataset;
		const gformId = data.gformId;
		if ( gformId ) {
			// if this.props.forms[ gformId ] is undefined, we'll render out a skeleton
			this.renderFormToElement( this.props.forms[ gformId ], e.target );
			if ( ! this.props.forms[ gformId ] ) {
				tbkApi.getGravityForm( gformId ).then( form => {
					this.props.dispatch({
						type: LOAD_GRAVITY_FIELDS,
						gformId,
						form,
					});
					this.renderFormToElement( form, e.target );
				});
			}
		}
	}

	renderFormToElement( form, element ) {
		const data = element.dataset;
		const gformId = data.gformId;
		const includeTitle = data.gformIncludeTitle && ( '0' !== data.gformIncludeTitle );
		const includeDescription = data.gformIncludeDescription && ( '0' !== data.gformIncludeDescription );
		const notifyEmail = data.notifyEmail || '';
		const renderedForm = form
			? ( form.response && 'success' === form.response.type )
				? <InlineNotification kind={form.response.type}
									  title={form.response.message}
									  subtitle={form.response.details ? <div dangerouslySetInnerHTML={{__html: form.response.details}}/> : ''}
									  onCloseButtonClick={() => {
										  this.renderFormToElement( {...form,response:null}, element );
									  }}
				/>
				: <Form className="tbk--gravity-form" id={'gravity-form-' + gformId} onSubmit={ (e) => {this.props.onSubmit( { gformId, notifyEmail }, e, this ) } } noValidate="novalidate">

					{includeTitle && <h1 className="tbk--gravity-form__heading">{form.title}</h1>}

					{includeDescription && form.description && <div className="tbk--gravity-form__description"><p>{form.description}</p></div>}

					<FormFields fields={form.fields} errors={form.response ? form.response.errors : null} controlled={false}/>

					{form.response &&
					<InlineNotification kind={form.response.type}
										title={form.response.message}
										subtitle={form.response.details ? <div dangerouslySetInnerHTML={{__html: form.response.details}}/> : ''}
					/>}
				</Form>
			: <Form className="tbk--gravity-form tbk--gravity-form--skeleton" id={'gravity-form-' + gformId}>
				<SkeletonText heading style={{width:'50%'}}/>
				<SkeletonText />
				<br/>
				<TextInputSkeleton style={{paddingBottom:'1em'}}/>
				<br/>
				<TextInputSkeleton style={{paddingBottom:'1em'}}/>
			</Form>;

		ReactDOM.render( renderedForm, element );
	}

	render() {
		return this.props.children;
	}
}

export default Reduxify( GravityForms );
export * from './actions';
export * from './functions';
export * from './components';
