import React from 'react';
import {map} from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {t} from 'i18next';
import FormField from './FormField';
import {checkConditionalLogic} from './FormFields.functions';
import {Button, Tab, Tabs as CarbonTabs } from 'carbon-components-react';
import {MobileProgressNav} from '../MobileProgressNav';
import {currentLanguage} from '../../services/i18n';

/**
 * I'm creating my own class for Tabs specifically to allow us to NOT switch tabs if the onSelectionChange listener
 * decides that we don't want to proceed ( like if there are errors on the tabs, for example.  The Tabs component in
 * Carbon does not allow that sort of thing natively, so I had to do it this way
 */
class Tabs extends CarbonTabs {
	selectTabAt = ( index, onSelectionChange) => {
		if (this.state.selected !== index) {
			onSelectionChange( index ).then( proceed => {
				if ( proceed ) {
					this.setState({
						selected: index,
					});
				}
			});
		}
	}
}

class FormFields extends React.Component {
	static propTypes = {
		fields: PropTypes.object,
		errors: PropTypes.object,
		showCompletion: PropTypes.bool,
		onChange: PropTypes.func,
		controlled: PropTypes.bool,
		onTabChange: PropTypes.func,
		currentPage: PropTypes.number,
		extraNavigation: PropTypes.func,
	};

	static defaultProps = {
		controlled: true,
		onTabChange: () => new Promise( resolve => resolve( true ) ),
		extraNavigation: (tab,currentTab,countTabs) => {}
	};

	constructor(props) {
		super(props);

		let state = {};
		if (!this.props.controlled) {
			state.values = {};
		}
		this.state = { ...state };

		if ( this.props.innerRef ) {
			this.props.innerRef( this );
		}
	}

	onUncontrolledChange(e) {
		if (e.target.type === 'checkbox') {
			const name = e.target.name.replace(/\[\]$/, '');
			let curr_selections = this.state.values[name];
			if (!curr_selections) {
				curr_selections = [e.target.value];
			} else {
				const find = curr_selections.indexOf(e.target.value);
				if (find === -1) {
					curr_selections.push(e.target.value);
				} else {
					curr_selections.splice(find, 1);
				}
			}
			this.setState({
				values: {
					...this.state.values,
					[name]: curr_selections
				}
			});
		} else {
			this.setState({
				values: {
					...this.state.values,
					[e.target.name]: e.target.value
				}
			});
		}
	}

	onTabChange(next,previous) {
		return this.props.onTabChange(next, previous).then( proceed => {
			if ( proceed ) {
				this.setState(prevState => ({
					currentPage: next,
				}));
				window.scrollTo(0, 0);
			}
			return proceed;
		});
	}

	render() {
		let tabs = [], tab = null;

		const onMaybeControlledChange = e => {
			return this.props.controlled ? this.props.onChange(e) : this.onUncontrolledChange(e);
		};
		return <React.Fragment>
			{this.props.fields ? map(this.props.fields, (field, name) => {
				if (field.type === 'tab' && tab) {
					// If we already have a tab object, then we need to add that to our tabs array
					tabs.push(tab);
				}

				// First, we'll check if this field should be included, based on conditional logic
				if (field.conditional_logic && !checkConditionalLogic(field.conditional_logic, this.props.fields)) {
					if (field.type === 'tab') {
						tab = false; // false is a special indicator to say that this tab has been excluded via conditional logic.
					}
					return null;
				}

				// Next, we'll check if the field is a tab.
				if (field.type === 'tab') {
					if (field.endpoint) {
						// If this is the endpoint marker for the tab set, then reset tab to null, and if we have tabs
						// to render, then render and return those.  Otherwise, just return nothing
						tab = null;
						if (tabs.length) {
							const key = tabs[0].key;
							const currentIndex = (this.state && typeof this.state.currentPage !== 'undefined' ) ? this.state.currentPage : ( (typeof this.props.currentPage !== 'undefined') ? this.props.currentPage : 0 );
							const renderedTabs = <div key={key} className="form-wrapper">
									<Tabs id={key} selected={currentIndex} onSelectionChange={tab => this.onTabChange(tab,currentIndex)}>
										{tabs.map((tab, index) => <Tab key={index} label={tab.label}
																	   className={classNames(tab.classNames)}>
											<h2 className="tabTitle">{tab.label}</h2>
											{tab.fields.map(tabField => tabField)}
											<div className="page-navigation-buttons">
												{index > 0 ? <Button kind="secondary" type="button" className="prev"
																	 onClick={() => this.onTabChange(index - 1,currentIndex)}>{t('Previous')}</Button> : null}
												{index < (tabs.length - 1) ?
													<Button kind="primary" type="button" className="next"
															onClick={() => this.onTabChange(index + 1,currentIndex)}>{t('Next')}</Button> : null}
												{this.props.extraNavigation(index,currentIndex,tabs.length)}
											</div>
										</Tab>)}
									</Tabs>
									<MobileProgressNav tabs={tabs.map((tab, index) => ({
										...tab,
										className: classNames(tab.classNames)
									}))} key={key} currentIndex={currentIndex} onChange={index => this.onTabChange(index,currentIndex)}/>
								</div>
							;
							tabs = [];
							return renderedTabs;
						}
					} else {
						tab = {
							...field,
							fields: []
						};
					}
					return null;
				} else if (tab === false) {
					// We have a field under a tab that has been excluded via conditional logic.
					return null;
				}

				// Render the field into a variable.  This will either get returned outright, or will be pushed to the fields
				// of the current tab, if there is a current tab
				const customClass = (field.wrapper) ? field.wrapper.class : null;
				const wrapperClasses = classNames({
					'tbk--form-fields__field-wrapper': true,
					[name]: true,
					'required': field && !!field.required,
					'tbk--form-fields__invalid': this.props.errors && this.props.errors[name],
					'tbk--acf-field': true,
					['tbk--acf-field--' + field.type]: true,
					[customClass]: true,
				});
				const {onChange, ...other} = this.props;
				let rendered = null;

				// Check if the field would not render before rendering its container.
				// Currently this only occurs if the field has a custom render override and that function returns null.
				if ( typeof field.field !== 'function' || field.field( field, other ) !== null ) {
					rendered = <div key={name} className={wrapperClasses}>
						<FormField field={this.props.controlled ? field : {...field, value: this.state.values[field.name]}}
						           onChange={onMaybeControlledChange} {...other} />
						{this.props.errors && this.props.errors[field.name] && <div
							className="tbk--form-fields__error">{this.props.errors[field.name].replace( field.label, 'en' === currentLanguage() ? 'This' : '' )}</div>}
						{field && field.description &&
						 <div className="tbk--form-fields__description">{field.description}</div>}
					</div>;
				}

				if (tab) {
					tab.fields.push(rendered);
					return null;
				} else {
					return rendered;
				}
			}) : ''}
		</React.Fragment>;
	}

}

export {FormFields};
