import React from 'react';
import {FormFields} from '../FormFields';
import {Button} from 'carbon-components-react';
import {normalizeFields} from '../FormFields.functions';
import Label from './Label';
import {t} from 'i18next';

export default props => {
	const {field,label,errors,onChange,onAddRepeaterRow,onDeleteRepeaterRow,tooltip} = props;

	let value = field.value;
	if (field.min) {
		if (!value) {
			value = new Array(field.min);
			value.fill({});
		} else if (value.length < field.min) {
			const prevLength = value.length;
			value.length = field.min;
			value.fill({}, prevLength, field.min);
		}
	}

	// We need a unique key for the repeater given the number of rows it has.  It's that way because when we delete
	// a row, we need to do a full-fresh re-render of the element, otherwise rows might show values from the previous
	// iteration of another row.  We do this after the field.min check above so that we have a consistent key
	const key = 'repeater-with-' + ( value ? value.length : 0 ) + '-rows';

	return <React.Fragment key={key}>
		<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
		{value ? value.map( ( row, index ) => {
			const sub_fields = normalizeFields(
				field.sub_fields.map( sub_field => {
					return {
						...sub_field,
						key: field.name+'-'+sub_field.name+'-'+index,
						name: field.name + '[' + index + ']['+ sub_field.name + ']',
						value: row[ sub_field.name ]
					}
				})
			);
			return <div key={'key-'+index} className="tbk--repeater">
				<FormFields fields={sub_fields} errors={errors} onChange={onChange} />
				{(index >= field.min) &&
				<Button type="button" onClick={() => {
					if ( onDeleteRepeaterRow ) {
						onDeleteRepeaterRow({detail: { field, index }});
					}
				}}>{t('Delete')}</Button>}
			</div>
		}) : null}
		<Button type="button" onClick={() => {
			if ( onAddRepeaterRow ) {
				onAddRepeaterRow({detail: { field }});
			}
		}}>{field.button_label || t('Add Row')}</Button>
	</React.Fragment>
};
