import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import {LOAD_GRAVITY_FIELDS} from './actions';
import tbkApi from '../../services/tbkApi';
import {each} from 'lodash';
import { gtmPush } from '../../components/GoogleTagManager';

export const propTypes = {
	forms: PropTypes.object,
	layout: PropTypes.string
};

export const defaultProps = {
	forms: {},
	layout: 'default'
};

export const mapStateToProps = {
	gravity: (state,action) => {
		switch( action.type ) {
			case LOAD_GRAVITY_FIELDS:
				return {
					...state,
					forms: {
						...state.forms,
						[action.gformId]: action.form,
					}
				};
			default:
				return state;
		}
	}
};

export const mapDispatchToProps = {
	onSubmit: ( args, e, controller ) => {
		const { gformId, notifyEmail } = args;
		e.preventDefault();
		return dispatch => {
			const submit = e.target.querySelector('[type="submit"]');
			submit.disabled = true;
			// GForms expects array elements (such as checkboxes) in the format of input_i.j (starting at 1) instead of input_i[j]
			let formData = serialize(e.target, 'hash');
			each(formData, (field, key) => {
				if (Array.isArray(field)) {
					delete formData[key];
					let i = 1;
					each(field, (inner_field) => {
						formData[key + '.' + i++] = inner_field;
					});
				}
			});
			tbkApi.postForm(Object.assign({form_id: gformId, notify_email: notifyEmail}, formData)).then(response => {
				if ( 'success' === response.type ) {
					gtmPush({
						event: 'Form_Submission',
						formName: controller.props.forms[ gformId ].title,
					});
				}
				submit.disabled = false;
				controller.renderFormToElement({
					...controller.props.forms[ gformId ],
					response
				},document.getElementById( 'gform-' + gformId ))
			});
		}
	}
};
