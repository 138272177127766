import React from 'react';
import { TextArea } from 'carbon-components-react';
import Label from './Label';

export default props => {
	const {field,label,onChange,tooltip} = props;
	const value = ( null === field.value ) ? field.default_value : field.value;

	return <React.Fragment>
	<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
	<TextArea
		id={field.key}
		labelText=""
		name={field.name}
		value={value || undefined}
		placeholder={field.placeholder}
		required={!!field.required}
		onChange={onChange}
	/></React.Fragment>;
};
