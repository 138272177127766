import React from 'react';
import { t } from 'i18next';
import { PostContent } from '../../components/PostContent';
// import { Button } from 'carbon-components-react';

export const GravityForm = ({gformId, includeTitle, includeDescription, layout, ...other}) => {
	layout = !!layout ? layout : 'default';

	const sprintf = [
		gformId,
		gformId,
		!!includeTitle ? 1 : 0,
		!!includeDescription ? 1 : 0,
	];

	let div = t(
		'<div class="gform" id="gform-%s" data-gform-id="%s" data-gform-include-title="%s" data-gform-include-description="%s"></div>',
		{
			postProcess: 'sprintf', sprintf
		}
	);

	const wrapperClass = [
		'tbk--gform-wrapper',
		'tbk--gform-wrapper__' + layout
	];

	return <PostContent postContent={div} className={wrapperClass.join(' ')}/>;
};
