import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import { slugify } from './functions';

import { Icomoon } from '../Icomoon';
import { Button } from 'carbon-components-react';


const propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	title: PropTypes.string,
	icon: PropTypes.string,
	href: PropTypes.string,
	active: PropTypes.bool,
	count: PropTypes.number,
	isOpen: PropTypes.bool,
	exact: PropTypes.bool,
}

const defaultProps = {
	title: '',
	icon: 'bullet',
	onClick: () => void 0
}

class MainMenuItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: !!props.isOpen,
		};

		this.toggleCollapse = this.toggleCollapse.bind(this);
	};

	wrapperClasses = classNames(
		'tbk--main-menu-item',
		{
			'tbk--main-menu-item--dropdown': this.props.children,
			'tbk--main-menu-item--active': this.props.active,
		},
		this.props.className
	);

	childClasses = classNames(
		'tbk--main-menu-item',
		'tbk--main-menu-item--child',
		{
			'tbk--main-menu-item--dropdown': this.props.children,
			'tbk--main-menu-item--active': this.props.active,
		},
		this.props.className
	);

	titleSlug() {
		return slugify(this.props.title)
	};

	toggleCollapse() {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}));
	}

	render() {
		if (this.props.children) {
			return (
				<li className={this.wrapperClasses} role="none">
					<Button id={this.titleSlug() + '--submenu-btn'} kind="ghost" className="tbk--main-menu-item__btn" onClick={this.toggleCollapse} aria-haspopup="true" aria-controls={this.titleSlug() + '--submenu'} aria-expanded={this.state.isOpen ? 'true' : 'false'}>
						<span className="tbk--main-menu-item__btn-inner">
							{this.props.icon && <Icomoon icon={this.props.icon} className="tbk--main-menu-item__icon"/>}
							{this.state.isOpen &&
								<div className="tbk--main-menu-item__title tbk--main-menu-item__title--open">{this.props.title}</div>
							}
							{this.state.isOpen &&
								<Icomoon icon="minus" className="tbk--main-menu-item--dropdown__icon" />
							}
							{!this.state.isOpen &&
								<div className="tbk--main-menu-item__title">{this.props.title}</div>
							}
							{!this.state.isOpen &&
								<Icomoon icon="plus" className="tbk--main-menu-item--dropdown__icon" />
							}
						</span>
					</Button>
					{this.state.isOpen &&
						<ul id={this.titleSlug() + '--submenu'} className={this.childClasses} role="menu" aria-label={this.titleSlug() + ' ' + t('submenu')} 	aria-labelledby={this.props.title + '--submenu-btn'}>
							{this.props.children}
						</ul>
					}
				</li>
			)
		} else {
			// A little helper to know if the home page link should be active ( needed because that's "exact" on '/', but
			// links that start with /home also qualify ).
			const isActive = (match,location) => {
				if ( ! match && ( '/' === this.props.href ) && !!location.pathname.match(/^\/home/) ) {
					return true;
				}
				return !!match;
			};
			return (
				<li className={this.wrapperClasses} role="none">
					<NavLink to={this.props.href} onClick={ e =>{ this.props.onClick(e); document.body.scrollIntoView({block:'start',behavior: 'smooth'})} } className="tbk--main-menu-item__link" exact={this.props.exact} activeClassName="tbk--main-menu-item__link--active" role="menuitem" isActive={isActive}>
						{this.props.icon && <Icomoon icon={this.props.icon} className="tbk--main-menu-item__icon"/>}
						<div className="tbk--main-menu-item__title">{this.props.title}</div>
						{this.props.count > 0 &&
							<div className="tbk--main-menu-item__count">{this.props.count}</div>
						}
					</NavLink>
				</li>
			);
		}
	}
}

MainMenuItem.propTypes = propTypes;
MainMenuItem.defaultProps = defaultProps;

export { MainMenuItem };
