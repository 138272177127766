import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
// import { Button } from 'carbon-components-react';
import { Link } from "react-router-dom";

const propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	title: PropTypes.string,
	placement: PropTypes.oneOf(['top-nav', 'mobile-nav', 'default']),
	link: PropTypes.string,
};

const defaultProps = {
	placement: 'default',
};

const BrandLogo = ({ children, className, title, placement, link, ...other }) => {
	const wrapperClasses = classNames(
		'tbk--brand-logo',
		{
			'tbk--brand-logo--default' : placement === 'default',
			'tbk--brand-logo--top-nav' : placement === 'top-nav',
			'tbk--brand-logo--mobile-nav' : placement === 'mobile-nav',
		},
		className
	);

	return (
		<Link to={link ? link : ''} title={title} className={wrapperClasses} {...other}>
			{children &&
				children
			}

			{!children &&
				title
			}
		</Link>
	);
};


BrandLogo.propTypes = propTypes;
BrandLogo.defaultProps = defaultProps;

export { BrandLogo };
