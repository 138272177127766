import i18n from "i18next";
import i18next, {changeLanguage} from "i18next";
import sprintf from "i18next-sprintf-postprocessor";
import i18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import translations from '../locales';
import moment from 'moment';
import 'moment/locale/fr';
import tbkApi from './tbkApi';

i18n.use(sprintf).use(i18nextBrowserLanguageDetector).init({
	// we init with resources
	resources: translations,
	fallbackLng: "en",
	debug: false,

	// have a common namespace used around the full app
	ns: ["translations"],
	defaultNS: "translations",

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ","
	},

	detection: {
		lookupQuerystring: 'lang'
	},

	react: {
		wait: true
	}
});

export const changeLang = lang => {
	if (currentLanguage() === lang) return;
	changeLanguage(lang, () => {
		moment.locale(lang);
		tbkApi.changeLanguage({lang}).then(() => window.location.reload());
	})
};

/**
 * The initial browser language detection will detect "en_US" as the langauge.  Several places we use this for the
 * locale, which expects only "en".  This function encapsulates normalizing the current language.
 * 
 * @returns {string}
 */
export const currentLanguage = () => {
	return ( i18next.language || 'en' ).substr(0,2);
};

export default i18n;
