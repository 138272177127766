import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tile } from 'carbon-components-react';

class GeneralPage extends Component {

	static propTypes = {
		title: PropTypes.string,
		featuredImage: PropTypes.object,
	};

	static defaultProps = {
		title: null,
		featuredImage: null
	};

	render() {
		return (
			<div className="tbk--general-page">
				<Tile>
					<h1 className="tbk--general-page__title">{this.props.title}</h1>
					{ this.props.featuredImage && <img className="tbk--general-page__featured-image" src={this.props.featuredImage.sizes['hero-banner']} alt={this.props.title}/> }
					{this.props.children}
				</Tile>
			</div>
		)
	}
}

export { GeneralPage };
