import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {t} from 'i18next';
import {SkeletonText} from 'carbon-components-react';

import {Icomoon} from '../Icomoon';

class UserMenu extends Component {

	static propTypes = {
		user: PropTypes.object,
		className: PropTypes.string,
		kind: PropTypes.oneOf(['dropdown', 'navbar', 'side-nav', 'mobile']),
		closeMenu: PropTypes.func,
		assets: PropTypes.object,
	};

	static defaultProps = {
		user: {},
		kind: 'dropdown',
		className: '',
		closeMenu: () => void 0,
		assets: {}
	};

	render() {

		const {kind, user, className, closeMenu, ...other} = this.props;

		const wrapperClasses = classNames(
			'tbk--user-menu',
			{
				'tbk--user-menu--dropdown': kind === 'dropdown',
				'tbk--user-menu--navbar': kind === 'navbar',
				'tbk--user-menu--side-nav': kind === 'side-nav',
				'tbk--user-menu--mobile': kind === 'mobile',
			},
			className
		);

		switch (kind) {
			case 'dropdown':
				return (
					<div className={wrapperClasses} {...other}>
						<div className="tbk--user-menu__name">
							{!!user.last_name ? <span>
									{user.last_name}{user.first_name ? ', ' + user.first_name : ''}
								</span>
								: <span>
									<SkeletonText/>
								</span>
							}
							<Icomoon icon="gear"/>
						</div>
						<Icomoon icon="triangle-down" size="xs"/>
					</div>
				);
			case 'navbar':
				return [
					<React.Fragment>
						<li className="bx--overflow-menu-options__option">
							<Link className="bx--link bx--overflow-menu-options__btn"
							      to="/edit-profile">{t('My Account')}</Link>
						</li>
						{(this.props.assets.appPages && this.props.assets.appPages.privacy_policy.ID) ?
							<li className="bx--overflow-menu-options__option">
								<Link className="bx--link bx--overflow-menu-options__btn"
								      to={this.props.assets.appPages.privacy_policy.path}>
									{this.props.assets.appPages.privacy_policy.title}
								</Link>
							</li> : null}
						{(this.props.assets.appPages && this.props.assets.appPages.accessibility_policy.ID) ?
							<li className="bx--overflow-menu-options__option">
								<Link className="bx--link bx--overflow-menu-options__btn"
								      to={this.props.assets.appPages.accessibility_policy.path}>
									{this.props.assets.appPages.accessibility_policy.title}
								</Link>
							</li> : null}
					</React.Fragment>
				];
			case 'side-nav':
			case 'mobile':
			default:
				return (
					<ul className={wrapperClasses} {...other} >
						<li>
							<Link className="tbk--user-menu__link" to="/edit-profile">
								<div className="tbk--user-menu__content">
									<div className="tbk--user-menu__name">
										<Icomoon icon="gear"/>
									</div>
									{kind === 'side-nav' &&
									<div className="tbk--user-menu__position">
										{user.title}
									</div>
									}
									<div className="tbk--user-menu__edit">
										{t('Edit Profile')}
									</div>
								</div>
							</Link>
						</li>
					</ul>
				);
		}
	}
}

export {UserMenu};
