import tbkApi from './tbkApi';
import store from './ReduxStore';
import {UPDATE_USER_COUNTS} from '../containers/App';

export const clearUnread = postTypes => {
	return tbkApi.clearUnread(postTypes).then(counts => {
		store.dispatch({
			type: UPDATE_USER_COUNTS,
			counts
		});
	});
};

/**
 * Extracts a particular field from the last hit in the feed.  The field should be a string formatted like a path to
 * the field in the object.  So, if we're looking for the post_date_gmt field on an article object, we would pass in
 * 'article.post_date_gmt' as field.  We'll look for that path on the last item in the feed.hits array.
 *
 * @param {object|null} feed  might be null, should contain a .hits member
 * @param {string}      field see above description
 *
 * @returns {string} empty string if we don't have any current hits in the feed
 */
export const extractAfter = (feed, field) => {
	if (feed && feed.hits && feed.hits.length) {
		const last = feed.hits[feed.hits.length - 1];
		const parts = field.split('.');
		if (last) {
			let since = last;
			parts.forEach(part => {
				since = since ? since[part] : '';
			});
			return since;
		}
	}
	return '';
};
