import React from 'react';
import {t} from 'i18next';
import {Link} from 'react-router-dom';
import {Tile} from 'carbon-components-react';

const NotFound = ({location}) => (
	<Tile className="tbk--page tbk--page-not-found">
	<div className="page-404-heading-column">
		<h1 className="page-404-heading">
			<strong>{t( 'Oh no!')} </strong>
			<small>{t( '404 error.')}</small>
		</h1>
	</div>
	<div className="page-404-content-column">
		<h2 className="page-404-content-heading">{t('Page not found.')}</h2>
		<div className="page-404-content">
			<p>
				{t( 'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.' )}
			</p>
		</div>
		<div className="page-404-cta-container">
			<Link className="bx--btn bx--btn--primary" to="/">
				{t( 'Go Home' )}
			</Link>
		</div>
	</div>
	</Tile>
);

export default NotFound;
