import React from 'react';
import { RadioButton, RadioButtonGroup } from 'carbon-components-react';
import Label from './Label';
import { map } from 'lodash';

export default props => {

	const {field,label,onChange,tooltip} = props;
	const radioChoices = field.choices ? map(field.choices, (choiceName, value) => {
		return <RadioButton
			key={field.name + '-' + value}
			value={value+''}
			id={field.name + '-' + value}
			labelText={choiceName}
		/>
	}) : '';
	const value = ( null === field.value ) ? field.default_value : field.value;

	return <React.Fragment>
		<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
		<RadioButtonGroup
			id={field.key}
			name={field.name}
			valueSelected={value+''}
			required={!!field.required}
			onChange={(selected,value,e) =>{
				onChange(e);
			}}
		>{radioChoices}</RadioButtonGroup>
	</React.Fragment>;
};
