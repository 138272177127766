/**
 * Polyfill for CustomEvent that will (hopefully) work in IE11
 *
 * @param {string}      event
 * @param {HTMLElement} element
 * @param {object}      [details]
 */
const dispatchCustomEvent = ( event, element, details ) => {
	const init = Object.assign({
		bubbles: true,
		cancelable: true,
		detail: null,
	}, details || {} );

	if ( typeof CustomEvent === 'function' ) {
		element.dispatchEvent( new CustomEvent( event, init ) );
	} else {
		const e = document.createEvent( 'CustomEvent' );
		e.initCustomEvent( event, init.bubbles, init.cancelable, init.detail );
		element.dispatchEvent( e );
	}
};

export default dispatchCustomEvent;
