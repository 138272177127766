import {each, isArray, isObject, pickBy} from 'lodash';

const _findValue = ( key, fields ) => {
	let value;
	if ( fields[ key ] ) {
		value = fields[ key ].value;
		if ( null === value ) {
			value = fields[ key ].default_value;
		}
	} else {
		each( fields, field => {
			switch ( field.type ) {
				case 'group':
					const valueInGroup = _findValue( key, normalizeFields( field.sub_fields ) );
					if ( typeof valueInGroup !== 'undefined' ) {
						value = valueInGroup;
					}
					break;
				default:
					break;
			}
		});
	}
	return value;
};

export const normalizeFields = fields => {
	let normalized = {};
	each( fields, field => {
		normalized[ field.key ] = field;
	});
	return normalized;
};

export const checkConditionalLogic = ( logic, fields ) => {
	let pass = false;

	const maybeNumber = value => {
		const intVal = ( 'boolean' === typeof value ) ? ( value ? 1 : 0 ) : parseInt( value, 10 );
		return isNaN( intVal ) ? value : intVal;
	};

	logic.forEach( or_condition => {
		if( !isArray( or_condition ) && isObject( or_condition ) ) {
			or_condition = [ or_condition ];
		}
		let condition_pass = true;
		or_condition.forEach( and_condition => {
			const checkValue = _findValue( and_condition.field, fields );
			let valuesAreEqual;
			if ( isArray( checkValue ) ) {
				valuesAreEqual = checkValue.indexOf( maybeNumber( and_condition.value ) ) !== -1;
			} else {
				valuesAreEqual = maybeNumber( checkValue ) === maybeNumber( and_condition.value );
			}
			const notEmpty = ( isArray( checkValue ) ) ? Boolean( checkValue.length ) : Boolean( checkValue );
			switch ( and_condition.operator ) {
				case '!=empty':
					condition_pass = condition_pass && notEmpty;
					break;
				case '==empty':
					condition_pass = condition_pass && !notEmpty;
					break;
				case '==pattern':
					condition_pass = condition_pass && new RegExp(and_condition.value).test(checkValue);
					break;
				case '==contains':
					condition_pass = checkValue.includes(condition_pass.value);
					break;
				case '!=':
					condition_pass = condition_pass && ! valuesAreEqual;
					break;
				case '==':
				default:
					condition_pass = condition_pass && valuesAreEqual;
					break;
			}
		});
		pass = pass || condition_pass;
	});
	return pass;
};

export const getTabs = fields => {
	return pickBy(fields, field => field.type === 'tab' && !field.endpoint);
};

/**
 * Figure out the percent completion for the form.
 *
 * @param {object} fields the fields ( should have values applied )
 *
 * @returns {{}}
 */
export const getFormCompletion = fields => {
	// completion will be an object where the key is the key for a tab and the value is a boolean to indicate if
	// it is complete or not, as well as a "percentage" parm which is the overall percentage complete
	let completion = {};

	let activeTab = 'form'; // If this is not a tabbed form, then
	each( fields, ( field, key ) => {
		if ( 'tab' === field.type && ! field.endpoint ) {
			activeTab = field.key;
		} else {
			if ( typeof completion[ activeTab ] === 'undefined' ) {
				completion[ activeTab ] = true;
			}
			completion[ activeTab ] = completion[ activeTab ] && _checkField( field, fields );
			if ( ( 'repeater' === field.type ) && field.value && field.value.length ) {
				field.value.forEach( row => {
					field.sub_fields.forEach( subfield => {
						completion[ activeTab ] = completion[ activeTab ] && _checkField({...subfield,value: row[ subfield.name ], fields});
					});
				});
			} else if ( 'group' === field.type ) {
				field.sub_fields.forEach( subfield => {
					completion[ activeTab ] = completion[ activeTab ] && _checkField(subfield, fields);
				});
			}
		}
	});

	completion.percentage = Object.values( completion ).filter( complete => complete ).length / Object.values( completion ).length;

	return completion;
};

const _checkField = ( field, fields ) => {
	if ( field.required && ( ! field.value || ( isArray( field.value ) && ! field.value.length ) ) ) {
		if ( ! field.conditional_logic || checkConditionalLogic( field.conditional_logic, fields ) ) {
			return false;
		}
	}
	return true;
};