import GoogleTagManager from './GoogleTagManager';

export const gtmPush = event => {
	const dataLayer = window.dataLayer || [];

	// According to discussion at https://www.simoahava.com/gtm-tips/add-the-event-key-to-datalayer-pushes/
	// adding the event key to pushes forces the push to go right away.
	let args = event;
	if ( event.Category && ! args.event ) {
		args.event = 'ga-event';
	}

	if ( 'development' === process.env.NODE_ENV ) {
		console.log( ['gtm', args] );
	} else {
		dataLayer.push( args );
	}
};

export default GoogleTagManager;
