import React from 'react';
import Label from './Label';
import Hidden from './Hidden';
import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatable from 'react-select/lib/AsyncCreatable';
import { isArray } from 'lodash';
import { t } from 'i18next';

class Taxonomy extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			hasInitialized: false,
		};

		this.optionsCache = {};
	}

	componentDidMount() {
		if ( this.props.onLoadTaxonomyOptions ) {
			this.props.onLoadTaxonomyOptions({
				detail: {
					field: this.props.field,
					initial: true,
					callback: initialValue => {
						this.cacheOptions( initialValue );
						this.setState({hasInitialized:true});
					}
				}
			})
		}
	}

	/**
	 * This is providing a mechanism to lookup the { label, value } objects for options that we've already loaded from
	 * the server.  I couldn't figure out a simple way for <AsyncSelect/> to be able to report back its own options, so
	 * I implemented the cache myself.
	 */
	cacheOptions( options ) {
		options.forEach( option => {
			this.optionsCache[ option.value ] = option;
		});
	}

	render() {

		if ( ! this.state.hasInitialized ) {
			return null;
		}

		const {field,label,tooltip} = this.props;

		let value = field.value;
		let selectedValue;
		if ( field.multiple ) {
			if ( ! isArray( value ) ) {
				if ( typeof value === 'undefined' ) {
					value = [];
				} else {
					value = [ value ];
				}
			}
			selectedValue = [];
			value.forEach( selected => {
				if ( this.optionsCache[ selected ] ) {
					selectedValue.push( this.optionsCache[ selected ] );
				}
			})
		} else {
			if ( isArray( value ) ) {
				value = value[0];
			}
			if ( this.optionsCache[ value ] ) {
				selectedValue = this.optionsCache[ value ];
			}
		}
		const onChange = value => {
			if ( this.props.onChangeSelectValue ) {
				this.props.onChangeSelectValue({detail: { field, value, newTermCallback: newTerm => {
					// newTermCallback is a way to prime the internal cache of this component with the new term that
					// has just been created.  It's necessary so the new term shows up in this controlled component
					this.cacheOptions([newTerm]);
				} }});
			}
		};
		const onLoadOptions = ( input, callback ) => {
			if ( this.props.onLoadTaxonomyOptions ) {
				this.props.onLoadTaxonomyOptions({detail: {field, input, callback: options => {
					this.cacheOptions( options );
					callback( options );
				} } } );
			}
		};
		const TaxonomySelect = field.add_term ? AsyncCreatable : AsyncSelect;

		return <React.Fragment>
			<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
			{/* Hidden Element so that we actually get the value as a form field that gets submitted */}
			{field.multiple
				? value.map( selection => <Hidden key={selection} field={{name:field.name + '[]',value:selection}} /> )
				: <Hidden key={value} field={{name:field.name,value}} />}
			<TaxonomySelect
				isMulti={field.multiple}
				isClearable
				cacheOptions
				loadOptions={onLoadOptions}
				defaultOptions
				placeholder={t('Select...')}
				value={selectedValue}
				onChange={onChange}
			/>
		</React.Fragment>
	}
}

export default Taxonomy;
