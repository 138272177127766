import React from 'react';
import { FormFields } from '../FormFields';
import { normalizeFields } from '../FormFields.functions';
import Label from './Label';

export default props => {
	const {field,label,errors,onChange,tooltip} = props;
	const sub_fields = normalizeFields(
		field.sub_fields.map( sub_field => {
			return {
				...sub_field,
				name: field.name + '[' + sub_field.name + ']'
			}
		})
	);

	return <React.Fragment>
		<Label htmlFor={field.key}>{label}</Label> {tooltip}
		<FormFields fields={sub_fields} errors={errors} onChange={onChange}/>
	</React.Fragment>
};
