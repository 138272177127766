import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
const LOG_OUT_USER = 'app/LOG_OUT_USER';

const basename = process.env.REACT_APP_BASENAME || '/';

export const history = createHistory({
    basename
});

const initialState = {};
const enhancers = [];
const middleware = [
    thunk,
    routerMiddleware(history)
];

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const makeRootReducer = (reducers) => {
    return ( state, action ) => {
        // When logging out, we want to reset all non-anonymous pieces of the store ( so, not app and login )
        if ( LOG_OUT_USER === action.type ) {
            Object.keys( store.asyncReducers ).forEach( key => {
                if ( ['app','login'].indexOf( key ) === -1 ) {
                    state[ key ] = undefined;
                }
            });
        }
        return combineReducers({
            router: connectRouter(history),
            ...reducers
        })(state,action);
    };
};

const store = createStore(
    makeRootReducer(),
    initialState,
    composedEnhancers
);
store.asyncReducers = {};

export const injectReducer = (key,reducer) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer( makeRootReducer(store.asyncReducers) );
};

export default store;
