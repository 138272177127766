import PropTypes from 'prop-types';
import {
	APP_HEARTBEAT,
	LOG_OUT_USER,
	SET_APP_DATA,
	SET_APP_NOTIFICATIONS,
	READ_NOTIFICATION,
	SET_APP_USER,
	UPDATE_USER_COUNTS
} from './App.actions';
import tbkApi from '../../services/tbkApi';
import update from 'immutability-helper';
import {gtmPush} from "../../components/GoogleTagManager";
import {SW_NEW_CONTENT} from "../../index";
import {clearUnread} from "../../services/Post";

export const propTypes = {
	user: PropTypes.object,
	assets: PropTypes.object,
	data: PropTypes.object,
	notifications: PropTypes.object,
	familyData: PropTypes.object,
	appIsDown: PropTypes.bool,
	newContentAvailable: PropTypes.bool,
	ping: PropTypes.bool,
};

export const defaultProps = {
	user: null,
	assets: null,
	data: null,
	notifications: null,
	familyData: null,
	appIsDown: null,
	newContentAvailable: null,
	ping: null,
};

export const mapStateToProps = {
	app: (state, action) => {
		switch (action.type) {
			case SET_APP_DATA:
				return {
					...state,
					assets: action.assets,
				};
			case SET_APP_USER:
				if (!!action.user.wpDashboard) {
					//redirect to the admin area
					window.location = action.user.wpDashboard;
					return state; // prevents flash of front end portal content
				}

				if (action.user && !action.user.isGuest) {
					gtmPush({
						userId: action.user.user_id
					});
				} else {
					gtmPush({
						userId: null
					});
				}
				return {
					...state,
					user: action.user,
				};
			case SET_APP_NOTIFICATIONS:
				return {
					...state,
					notifications: action.notifications,
				};

			case READ_NOTIFICATION:
				return {
					...state,
					notifications: {
						...state.notifications,
						read: [
							...state.notifications.read,
							action.id
						]
					}
				};
			case LOG_OUT_USER:
				gtmPush({
					userId: null
				});
				return {
					...state,
					user: {isGuest: true},
				};
			case UPDATE_USER_COUNTS:
				return {
					...state,
					user: update(state.user, {counts: {$merge: action.counts}})
				};
			case APP_HEARTBEAT:
				return {
					...state,
					ping: action.ping
				};
			case SW_NEW_CONTENT:
				return {
					...state,
					newContentAvailable: !action.clear,
				};
			default:
				return state;
		}
	}
};

export const mapDispatchToProps = {
	doLogout: () => {
		return (dispatch) => {
			dispatch({
				type: LOG_OUT_USER,
			});
			tbkApi.postLogout();
		}
	},
	onClearUnread: (post_types) => {
		return dispatch => {
			clearUnread(post_types);
		}
	}
};