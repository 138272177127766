import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {map} from 'lodash';
import {t} from 'i18next';
import {Icomoon} from '../Icomoon';

const propTypes = {
	kind: PropTypes.oneOf(['count', 'badge', 'full']),
	notifications: PropTypes.object,
	className: PropTypes.string,
	onClickItem: PropTypes.func,
};

const defaultProps = {
	kind: 'count',
	notifications: null,
	className: '',
	onClickItem: ID => {
	},
};

class Notifications extends React.Component {
	static propTypes = propTypes;
	static defaultProps = defaultProps;

	render() {
		if (!this.props.notifications) {
			return null;
		}
		const kind = this.props.kind;
		const className = this.props.className;
		const readNotifications = this.props.notifications.read || [];
		const notifications = this.props.notifications.notifications || [];
		const other = this.props.other;

		const wrapperClasses = classNames(
			'tbk--notifications',
			{
				'tbk--notifications--count': kind === 'count',
				'tbk--notifications--badge': kind === 'badge',
				'tbk--notifications--full': kind === 'full',
			},
			className
		);

		// Filtering out notifications from the count that have their post IDs already in the user's read meta.
		const newNotifications = notifications.filter(x => !readNotifications.find(y => parseInt(y, 10) === x.ID));

		// We are still displaying all unexpired notifications in the list, just not showing them as unread.
		const totalCount = notifications ? notifications.length : 0;
		const unreadCount = newNotifications ? newNotifications.length : 0;
		if (kind === 'count') {
			return totalCount;
		}

		if (kind === 'badge') {
			return (
				<div className={wrapperClasses} {...other}>
					<Icomoon icon="alert"/>
					{unreadCount > 0 &&
					<div className="tbk--notifications--badge__number">{unreadCount}</div>
					}
				</div>
			);
		}

		if (kind === 'full') {

			const heading = <div className="tbk--notifications__heading">
				<div className="tbk--notifications__heading-title">{t('Notifications')}</div>
			</div>;
			const notificationList = notifications ?
				map(notifications, (notification, key) => {
					let className = "tbk--notifications__item";
					if (!readNotifications.includes(notification.ID.toString())) {
						className += " tbk--notifications__item--unread";
					}
					return <li className={className} onClick={() => this.props.onClickItem(key)} key={key}>
						<div className="tbk--notifications__title">{notification.post_title}</div>
					</li>;
				})
				: null;

			return (
				<li className={wrapperClasses} {...other}>
					{heading}
					<ul className="tbk--notifications__list">
						{notificationList}
					</ul>
				</li>
			);
		}
	}
}

export {Notifications};
