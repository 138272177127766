import React from 'react';
import Label from './Label';

export default ({field,tooltip,label}) => {
	if ( ! field.message ) {
		return null;
	}

	//Render messages specifically out to html so that we can provide links, etc, from content
	function createMarkup( html ) {
		return { __html: html };
	}

	return <div>
		{ ( (field.label || tooltip) && label ) ? <Label>{label}</Label> : null}
		<p dangerouslySetInnerHTML={createMarkup(field.message)}></p>
	</div>
};
