import React from 'react';
import {Tooltip} from 'carbon-components-react';
import {GenericField} from './Fields';

const FormField = props => {

	const {field, ...other} = props;

	if (!field) {
		return null;
	}
	// Give controllers an opportunity to fully render the fields themselves.  This was helpful for the password reset
	// field where we needed a reference to the ReactPasswordStrength field.
	if (field.field) {
		return ( typeof field.field === 'function' ) ? field.field( field, other ) : field.field;
	}

	const tooltip = field.instructions
		? <Tooltip id={field.name + '-instructions'} triggerText="" clickToOpen>{field.instructions}</Tooltip>
		: null;

	return <GenericField label={field.label} tooltip={tooltip} {...props} />;

};

export default FormField;
