import React, { Component } from 'react';
import dispatchCustomEvent from '../../services/CustomEvents';

export const POST_CONTENT_GFORM = 'postContent/POST_CONTENT_GFORM';

class PostContent extends Component {
	constructor(props) {
		super(props);
		this.postContentRef = React.createRef();
		this.postContentProcessor = this.postContentProcessor.bind(this);
	}

	postContentProcessor() {
		const ref = this.postContentRef.current;
		const forms = ref.getElementsByClassName('gform');
		if ( forms.length ) {
			for ( let f = 0; f < forms.length; f++ ) {
				dispatchCustomEvent( POST_CONTENT_GFORM, forms[f] );
			}
		}

		// Force all external links to open in _blank
		const anchors = ref.getElementsByTagName('a');
		if ( anchors.length ) {
			for ( let a = 0; a < anchors.length; a++ ) {
				// Any link that contains :// will be treated as an external link.  Even if it's a direct link to
				// a file in the media library.  Even if it's a link to a page within the app.  Because it's a link
				// and not a react router link, it's better to open in a new tab so we don't lose track of where we
				// are within the app.
				if ( anchors[a].href && anchors[a].href.indexOf( '://' ) !== -1 ) {
					anchors[a].target = '_blank';
					anchors[a].onclick = () => {
					}
				}
			}
		}
	}

	componentDidMount() {
		this.postContentProcessor();
	}

	componentDidUpdate() {
		this.postContentProcessor();
	}

	render() {
		return <div className={this.props.className} dangerouslySetInnerHTML={{__html: this.props.postContent}} ref={this.postContentRef}/>
	}
}

export { PostContent };
