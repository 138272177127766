import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import iconPaths from './tbk-Portal-Icons.js';// the file exported from IcoMoon

const IcomoonResults = iconPaths.icons.map( icons => {
	return ([
		icons.properties.id,
		icons.properties.name,
		icons.icon.tags,
		icons.icon.paths.join(' ')
	]);
});

function getPath(iconName) {
	const icon = IcomoonResults.find( icon => {
		const names = icon[1].split( ',' ).map( x => x.trim() );
		return ( names.indexOf( iconName ) !== -1 );
	});

	if (icon) {
		return icon[3];
	} else {
		console.warn(`icon ${iconName} does not exist.`);
		return '';
	}
}

const propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string,
	color: PropTypes.string,
	gradient: PropTypes.string,
	size: PropTypes.oneOf(['', 'xs','lg','xl', 'fit']),
	title: PropTypes.string,
};

const defaultProps = {
	icon: 'dot',
};

const Icomoon = ({className, icon, gradient, color, size, title, ...other}) => {
	const wrapperClasses = classNames(
		'tbk--icomoon',
		{
			'tbk--icomoon--gradient' : gradient,
			'tbk--icomoon--xs' : size === 'xs',
			'tbk--icomoon--lg' : size === 'lg',
			'tbk--icomoon--xl' : size === 'xl',
			'tbk--icomoon--fit' : size === 'fit',
			['tbk--icomoon--icon-' + icon] : icon,
		},
		className
	);

	const rand = Math.floor(Math.random() * 90000);

	const customFill = gradient ? 'url(#IconGradient-' + icon + rand + ') currentColor': 'currentColor' ;

	const inlineStyle = {
		color: color,
		fill: customFill,

	}

	if (getPath(icon)) {
		return (
			<svg className={wrapperClasses} style={inlineStyle} viewBox="0 0 1024 1024">
				{ title && <title>{title}</title> }
				{ gradient &&
					<defs>
						<linearGradient id={'IconGradient-' + icon + rand } x1="0%" y1="100%" x2="100%" y2="0%">
							<stop offset="0%" stopColor="currentColor" ></stop>
							<stop offset="100%" stopColor={gradient} ></stop>
						</linearGradient>
					</defs>
				}
				<path d={getPath(icon)}></path>
			</svg>
		)
	} else {
		return '';
	}
};

Icomoon.propTypes = propTypes;
Icomoon.defaultProps = defaultProps;

export { Icomoon, IcomoonResults };
