import React from 'react';
import Label from './Label';
import Hidden from './Hidden';
import AsyncSelect from 'react-select/lib/Async';
import { isArray } from 'lodash';
import { t } from 'i18next';

class PostObject extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			hasInitialized: false,
			defaultOptions: true,
		};

		this.optionsCache = {};
	}

	componentDidMount() {
		if ( this.props.onLoadPostObjectOptions ) {
			this.props.onLoadPostObjectOptions({
				detail: {
					field: this.props.field,
					initial: true,
					callback: initialValue => {
						this.cacheOptions( initialValue );
						this.setState({hasInitialized:true});
					}
				}
			})
		}
	}

	/**
	 * This is providing a mechanism to lookup the { label, value } objects for options that we've already loaded from
	 * the server.  I couldn't figure out a simple way for <AsyncSelect/> to be able to report back its own options, so
	 * I implemented the cache myself.
	 */
	cacheOptions( options ) {
		options.forEach( option => {
			this.optionsCache[ option.value ] = option;
		});
	}

	render() {

		if ( ! this.state.hasInitialized ) {
			return null;
		}

		const {field,label,tooltip} = this.props;

		let value = field.value;
		let selectedValue;
		if ( field.multiple ) {
			if ( ! isArray( value ) ) {
				if ( typeof value === 'undefined' ) {
					value = [];
				} else {
					value = [ value ];
				}
			}
			selectedValue = [];
			value.forEach( selected => {
				if ( this.optionsCache[ selected ] ) {
					selectedValue.push( this.optionsCache[ selected ] );
				}
			})
		} else {
			if ( isArray( value ) ) {
				value = value[0];
			}
			if ( this.optionsCache[ value ] ) {
				selectedValue = this.optionsCache[ value ];
			}
		}

		// If the field itself has a selectedOption, then cache it and and set it as selected.  This is a way for
		// a higher order component to force the selected value after this PostObject component has already rendered
		// This also does the job of updating defaultOptions, if necessary ( if it's a new value, or the label has
		// changed )
		if ( field.selectedOption ) {
			this.cacheOptions( isArray( field.selectedOption ) ? field.selectedOption : [field.selectedOption] );
			selectedValue = field.selectedOption;

			let defaultOptions = [ ...( isArray( this.state.defaultOptions ) ? this.state.defaultOptions : [] ) ];
			let isDefaultOption = false;
			let needsUpdated = false;
			defaultOptions = defaultOptions.map( option => {
                if ( isArray( field.selectedOption ) ) {
                    field.selectedOption.forEach( selected => {
                        if ( selected.value === option.value ) {
                            isDefaultOption = true;
                            if ( option.label !== selected.label ) {
                                option.label = selected.label;
                                needsUpdated = true;
							}
                        }
                    })
                } else if ( field.selectedOption.value === option.value ) {
                    isDefaultOption = true;
                    if ( option.label !== field.selectedOption.label ) {
                        option.label = field.selectedOption.label;
                        needsUpdated = true;
                    }
                }
                return option;
			});
			if ( ! isDefaultOption ) {
				( isArray( field.selectedOption ) ? field.selectedOption : [ field.selectedOption ] ).forEach( option => {
					defaultOptions.push( option );
				});
                needsUpdated = true;
			}
			if ( needsUpdated ) {
				setTimeout(() => {
                    this.setState({
                        defaultOptions
                    });
				});
            }
		}

		const onChange = value => {
			if ( this.props.onChangeSelectValue ) {
				this.props.onChangeSelectValue({detail: { field, value }});
			}
		};
		const onLoadOptions = ( input, callback ) => {
			if ( this.props.onLoadPostObjectOptions ) {
				this.props.onLoadPostObjectOptions( { detail: { field, input, callback: options => {
					this.cacheOptions( options );
					callback( options );
					if ( true === this.state.defaultOptions ) {
						this.setState({
							defaultOptions: options
						});
					}
				} } } );
			}
		};

		return <React.Fragment>
			<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
			{/* Hidden Element so that we actually get the value as a form field that gets submitted */}
			{field.multiple
				? value.map( selection => <Hidden key={selection} field={{name:field.name + '[]',value:selection}} /> )
				: <Hidden key={value} field={{name:field.name,value}} />}
			<AsyncSelect
				isMulti={field.multiple}
				isClearable={!!field.allow_null}
				cacheOptions
				placeholder={t('Select...')}
				loadOptions={onLoadOptions}
				defaultOptions={this.state.defaultOptions}
				value={selectedValue}
				onChange={onChange}
			/>
		</React.Fragment>
	}
}

export default PostObject;
