// IE 11 polyfill
import 'core-js/shim';

// Fetch polyfill - only seems to be necessary for WKHTMLTOPDF to work
import 'unfetch/polyfill';

// FormData polyfill - for IE11
import 'formdata-polyfill';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './containers/App';
import store, { history } from './services/ReduxStore';
import GravityFormsProvider from './containers/GravityForms';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import dispatchCustomEvent from './services/CustomEvents';
import i18n from './services/i18n';

import './App.css';

if (typeof NodeList !== "undefined" && NodeList.prototype && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

export const SW_NEW_CONTENT = 'serviceWorker/NEW_CONTENT';

const target = document.getElementById('root');

render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<I18nextProvider i18n={i18n}>
				<GravityFormsProvider>
					<App />
				</GravityFormsProvider>
			</I18nextProvider>
		</ConnectedRouter>
	</Provider>,
	target
);
serviceWorker.register({
    onUpdate: () => {
        dispatchCustomEvent( SW_NEW_CONTENT, document );
    }
});
