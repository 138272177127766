import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	kind: PropTypes.oneOf(['mobile', 'side-nav']),
	isOpen: PropTypes.bool,
};

const defaultProps = {
	kind: 'side-nav',
};

const MainMenu = ({ children, className, kind, ...other }) => {
	const wrapperClasses = classNames(
		'tbk--main-menu',
		{
			'tbk--main-menu--side-nav': kind === 'side-nav',
			'tbk--main-menu--mobile': kind === 'mobile',
		},
		className
	);

	return (
		<ul className={wrapperClasses} {...other}>
			{children}
		</ul>
	)
};


MainMenu.propTypes = propTypes;
MainMenu.defaultProps = defaultProps;

export { MainMenu };
