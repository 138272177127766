import React from 'react';
import Checkbox from './Checkbox';
import DatePicker from './DatePicker';
import Group from './Group';
import Hidden from './Hidden';
import Message from './Message';
import Number from './Number';
import PostObject from './PostObject';
import Radio from './Radio';
import Repeater from './Repeater';
import Select from './Select';
import Taxonomy from './Taxonomy';
import Text from './Text';
import Textarea from './Textarea';
import TrueFalse from './TrueFalse';
import Button from './Button';

export const GenericField = ({field,...props}) => {
	let Field;
	switch ( field.type ) {
		case 'checkbox':		Field = Checkbox;		break;
		case 'date_picker':		Field = DatePicker;		break;
		case 'email':			Field = Text;			break;
		case 'phone':			Field = Text;			break;
		case 'group':			Field = Group;			break;
		case 'hidden':			Field = Hidden;			break;
		case 'message':			Field = Message;		break;
		case 'number':			Field = Number;			break;
		case 'post_object':		Field = PostObject;		break;
		case 'radio':			Field = Radio;			break;
		case 'repeater':		Field = Repeater;		break;
		case 'select':			Field = Select;			break;
		case 'taxonomy':		Field = Taxonomy;		break;
		case 'text':			Field = Text;			break;
		case 'textarea':		Field = Textarea;		break;
		case 'true_false':		Field = TrueFalse;		break;
		case 'button':		    Field = Button;		    break;
		default:				Field = props => <React.Fragment>
			Still need to implement {field.type}
		</React.Fragment>
	}

	return <React.Fragment>
		{field.prepend && <span className="acf-field--prepend">{field.prepend}</span>}
		<Field field={field} {...props}/>
		{field.append && <span className="acf-field--append">{field.append}</span>}
	</React.Fragment>
};
