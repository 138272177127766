import React, {Component} from 'react';
import {Loading} from 'carbon-components-react';
import {defaultProps, mapDispatchToProps, mapStateToProps, propTypes} from './Page.props';
import {RESET_PAGE, SETUP_PAGE} from "./Page.actions";
import tbkApi from '../../services/tbkApi';
import Reduxify from '../../services/ReduxifyController';
import {GeneralPage} from '../../components/GeneralPage';
import {PostContent} from '../../components/PostContent';
import {PageHeader} from '../../components/PageHeader';
import {PageFooter} from '../../components/PageFooter';
import {setTitle} from '../App';

class Page extends Component {
	static propTypes = propTypes;
	static defaultProps = defaultProps;
	static mapStateToProps = mapStateToProps;
	static mapDispatchToProps = mapDispatchToProps;

	constructor(props) {
		super(props);
		this.props.dispatch({
			type: RESET_PAGE,
		});

		if (!this.props.ID) {
			this.props.dispatch({
				type: SETUP_PAGE,
				page: {
					title: 'Not Found',
					content: "We're sorry, we couldn't find that page",
				}
			});
		} else {
			tbkApi.getPost(this.props.ID).then(post => {
				this.props.dispatch({
					type: SETUP_PAGE,
					page: {
						ID: post.ID,
						title: post.post_title,
						content: post.post_content,
						featuredImage: post.featured_image,
					}
				});
			});
		}
	}

	componentDidUpdate() {
		if ( this.props.page ) {
			setTitle( this.props.page.title );
		}
	}

	render() {
		//TODO: Needs frontend dashboard styling.  PageHeader and PageFooter are 3m style
		return this.props.page ? (
			<div className="tbk--page tbk--page--narrow">
				<PageHeader/>
				<GeneralPage size="narrow" {...this.props.page}>
					<PostContent postContent={this.props.page.content}/>
				</GeneralPage>
				<PageFooter/>
			</div>
		) : <Loading withOverlay={true}/>
	}
}

export default Reduxify(Page);
