import React from 'react';
import {Checkbox, Toggle} from 'carbon-components-react';
import Label from './Label';
import {t} from 'i18next';

export default props => {
	const {field,label,onChange,tooltip} = props;
	const value = ( null === field.value ) ? field.default_value : field.value;

	return <React.Fragment>
		{field.ui
			? <React.Fragment>
			<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
			<Toggle toggled={!!value}
			        name={field.name}
			        labelA={field.ui_off_text || t('No')}
			        labelB={field.ui_on_text || t('Yes')}
			        onChange={onChange}
			        id={field.key}/> </React.Fragment>
			:<div className="true_false_checkbox">
				<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
					<Checkbox
						id={field.key}
						hideLabel
						name={field.name}
						className='true_false--checkbox'
						checked={!!value}
						onChange={(checked,id,e) => onChange(e)}
					/>
				</div>}

	</React.Fragment>;
};
