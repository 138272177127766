export const loadUsersnap = clientId => {
	if ( ! window.usersnapLoaded ) {
		window.usersnapLoaded = true;
		const script = document.createElement("script");
		script.async = true;
		script.type = 'text/javascript';
		script.src = '//api.usersnap.com/load/' + clientId + '.js';
		document.head.appendChild(script)
	}
};
