import React from 'react';
import Label from './Label';
import MultiSelect from 'react-select';
import Hidden from './Hidden';
import { map, isArray } from 'lodash';
import { t } from 'i18next';

export default props => {
	const {field,label,onChangeSelectValue,tooltip} = props;

	let value = field.value || field.default_value;
	if ( ! isArray( value ) ) {
		if ( '' === value ) {
			value = [];
		} else {
			value = [ value ];
		}
	}

	const choices = ( typeof field.choices === 'function' ) ? field.choices( props ) : field.choices;

	// Remove values that are not available in choices
	value = value.filter( value => !!choices[ value ] );

	const options = map( choices, (label, choice) => {
		return { label, value: choice };
	});
	const selectedValue = value.map( key => {
		return {
			value: key,
			label: choices[ key ]
		}
	});
	const onChange = value => {
		if ( onChangeSelectValue ) {
			onChangeSelectValue({detail: { field, value }});
		}
	};
	return <React.Fragment>
		<div className="label-wrapper"><Label htmlFor={field.key}>{label}</Label> {tooltip}</div>
		{/* Hidden Element so that we actually get the value as a form field that gets submitted */}
		{field.multiple
			? value.map( selection => <Hidden key={selection} field={{name:field.name + '[]',value:selection}} /> )
			: <Hidden key={value} field={{name:field.name,value}} />}
		<MultiSelect
			value={selectedValue}
			isMulti
			placeholder={t('Select...')}
			options={options}
			onChange={onChange}
		/>
	</React.Fragment>
};
